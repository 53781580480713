a {
  &:link,
  &:visited {
    text-decoration: none;
  }
}

ul, ol {
  padding: 0;
  margin: 0;
  list-style: none;
}

li {
  list-style: none;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
  padding: 0;
}

body.no-events {
  pointer-events: none;
}

.is-mac-ios {
  .sidebar-content {
    padding: calculate_vw(20) 18px calculate_vw(20) 0 !important;
    @media (max-width: 960px) {
      padding: calculate_vw(20) 0 !important;
    }
  }
}
