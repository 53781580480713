@import '~@angular/material/theming';

@include mat-core();

$candy-app-primary: mat-palette($mat-blue);
$candy-app-accent: mat-palette($mat-yellow);
$candy-app-warn: mat-palette($mat-red);

$candy-app-theme: mat-light-theme($candy-app-primary, $candy-app-accent, $candy-app-warn);

@include angular-material-theme($candy-app-theme);

@import "@theme/style/variables/colors";
@import "@theme/style/variables/calculate_vw";
@import '@theme/style/components/base-reset';
@import '@theme/style/components/forms';
@import '@theme/style/components/drag-grop-forms';

@font-face {
	font-family: 'TahomaBold';
	font-weight: 700;
	font-style: normal;
	src: url('assets/fonts/TahomaBold.ttf');
}


/*
//// CUSTOM THEMES

  <p> wrapper class "Arabic" </p>
  <div class="Arabic">
    <form class="example-form">
      <mat-form-field>
        <input matInput placeholder="Favorite food" value="Arabic">
      </mat-form-field>

      <mat-form-field>
        <input matInput placeholder="Arabic">
      </mat-form-field>

      <mat-form-field>
        <input matInput placeholder="Favorite food" value="Arabic">
      </mat-form-field>
    </form>
  </div>

  <br/>
  <hr/>
  <br/>
  <p> no wrapper class </p>
  <form class="example-form">
    <mat-form-field>
      <input matInput placeholder="Favorite food" value="English">
    </mat-form-field>

    <mat-form-field>
      <input matInput placeholder="English 2">
    </mat-form-field>

    <mat-form-field>
      <input matInput placeholder="Favorite food" value="English">
    </mat-form-field>
  </form>

  <br/>
  <hr/>
  <br/>

  <p> wrapper class "English" </p>
  <div class="English">
    <form class="example-form">
      <mat-form-field>
        <input matInput placeholder="Favorite food" value="English">
      </mat-form-field>

      <mat-form-field>
        <input matInput placeholder="English 2">
      </mat-form-field>

      <mat-form-field>
        <input matInput placeholder="Favorite food" value="English">
      </mat-form-field>
    </form>
  </div>

*/
@import '~@angular/material/theming';

////1) define your fonts
//$general-typography: mat-typography-config(
//	$font-family: '"Open Sans", "Helvetica Neue", sans-serif',
//	$body-2: mat-typography-level(14px, 24px, 600)
//);

//1) define your fonts
$general-typography: mat-typography-config(
				$font-family: '"Source Sans Pro",sans-serif',
				$body-2: mat-typography-level(14px, 24px, 600)
);

@import url('https://fonts.googleapis.com/css?family=Pacifico|Shadows+Into+Light');

$arabic-typography: mat-typography-config(
	$font-family: '"Shadows Into Light", cursive'
);

$english-typography: mat-typography-config(
	$font-family: '"Pacifico", cursive'
);

// .. and formset the default font, we'll apply the other fonts later
@include mat-core($general-typography);

// 3) style specific component
// this would normaly be in a .scss file of your custom component
// therefore you'd just include those .scss files here
@mixin my-custom-component($theme) {
	// retrieve variables from current theme
	// only keep those you need and remove the others
	$primary: map-get($theme, primary);
	//$accent: map-get($theme, accent);
	//$warn: map-get($theme, accent);
	//$foreground: map-get($theme, foreground);
	//$background: map-get($theme, background);

	mat-form-field, .mat-form-field-appearance-legacy .mat-form-field-label {
		color: mat-color($primary);
	}
}

// 4) Helper which prevents us from adding all of our custom
// components themes one by one to each theme
@mixin custom-components-theme($theme) {
	@include my-custom-component($theme);
	//.. include all custom components mixins here
}

// 5) define default theme
$app-primary: mat-palette($mat-indigo);
$app-accent: mat-palette($mat-amber, A200, A100, A400);
$app-warn: mat-palette($mat-red);
$app-theme: mat-light-theme($app-primary, $app-accent, $app-warn);
@include angular-material-theme($app-theme);
@include custom-components-theme($app-theme);

// 6) define alternate themes
.Arabic {
	$arabic-primary: mat-palette($mat-orange);
	$arabic-accent: mat-palette($mat-blue, A200, A100, A400);
	$arabic-warn: mat-palette($mat-red);
	$arabic-theme: mat-light-theme($arabic-primary, $arabic-accent, $arabic-warn);

	@include mat-core($arabic-typography);
	@include angular-material-theme($arabic-theme);

	@include custom-components-theme($arabic-theme);
}

.English {
	$english-primary: mat-palette($mat-teal);
	$english-accent: mat-palette($mat-pink, A200, A100, A400);
	$english-warn: mat-palette($mat-red);
	$english-theme: mat-light-theme($english-primary, $english-accent, $english-warn);

	@include mat-core($english-typography);
	@include angular-material-theme($english-theme);

	@include custom-components-theme($english-theme);
}

html, body {
	height: 100%;
}

html {
	font-family: "Source Sans Pro", sans-serif;

	@media (min-width: 1200px) {
		@include font-size(15);
	}
}

.content-wrapper {
	height: 100%;
}

.main-content__wrapper {
	height: 100%;
	display: flex;
	flex-direction: column;
	flex: 1;
}

.mat-paginator {
	box-shadow: 0 2px 6px rgba(#000, .04);
	margin-top: auto;
}


.previous-page {
	background-color: transparent;
	border: none;
	padding: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	width: calculate_vw(40);
	height: calculate_vw(40);
	margin-left: auto;
	transition: transform .2s ease;
	position: absolute;
	right: calculate_vw(15);
	top: 50%;
	transform: translateY(-50%);

	&:hover {
		transform: translateX(calculate_vw(-2)) translateY(-50%);
	}

	&:active {
		transform: translateX(0) translateY(-50%);
	}

	&:focus {
		outline: none;
	}

	@media (max-width: $breakpoint1px) {
		width: elemVwSize(40, $breakpoint1);
		height: elemVwSize(40, $breakpoint1);
		right: elemVwSize(15, $breakpoint1);
	}

	@media (max-width: $breakpoint2px) {
		width: elemVwSize(40, $breakpoint2);
		height: elemVwSize(40, $breakpoint2);
		right: elemVwSize(15, $breakpoint2);
	}

	@media (max-width: $breakpoint3px) {
		width: elemVwSize(40, $breakpoint3);
		height: elemVwSize(40, $breakpoint3);
		right: elemVwSize(15, $breakpoint3);
	}

	@media (max-width: $breakpoint4px) {
		width: elemVwSize(40, $breakpoint4);
		height: elemVwSize(40, $breakpoint4);
		right: elemVwSize(15, $breakpoint4);
	}
}

.previous-page__icon {
	fill: $form-primary-text-color;
	height: 100%;
	width: 100%;
}

.mobile-forms {
	position: relative;

	.previous-page {
		bottom: 100%;
		top: auto;
		right: 0;
		width: 35px;
		height: 30px;
		background-color: #fafafa;
		padding-left: 5px;
		padding-right: 5px;
	}
}

.bage_container {
	position: relative;
	min-height: calculate_vw(60);
	margin-bottom: calculate_vw(10);
	background-color: rgba(#000, .07);
	display: flex;
	flex-direction: column;
	justify-content: center;

	@media (max-width: $breakpoint1px) {
		margin-bottom: elemVwSize(10, $breakpoint1);
		min-height: elemVwSize(60, $breakpoint1);
	}

	@media (max-width: $breakpoint2px) {
		margin-bottom: elemVwSize(10, $breakpoint2);
		min-height: elemVwSize(60, $breakpoint2);
	}

	@media (max-width: $breakpoint3px) {
		margin-bottom: elemVwSize(10, $breakpoint3);
		min-height: elemVwSize(60, $breakpoint3);
	}

	@media (max-width: $breakpoint4px) {
		margin-bottom: elemVwSize(10, $breakpoint4);
		min-height: elemVwSize(60, $breakpoint4);
	}

	@media (max-width: $breakpoint5px) {
		display: none;
	}

	.cdk-drop-list {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		padding: calculate_vw(15) 0;
	}

	.drag-drop-block {
		min-height: calculate_vw(60);
		position: relative;

		span {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
		}
	}
}

.example-box__content {
	margin-left: calculate_vw(-20);
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	margin-bottom: calculate_vw(-20);
	padding-right: 5%;
	width: 100%;
}

.example-box {
	cursor: move;
	background: #43425D;
	flex-basis: auto;
	padding: calculate_vw(5) 0;
	padding-left: calculate_vw(25);
	padding-right: calculate_vw(10);
	display: flex;
	align-items: center;
	margin-left: calculate_vw(40);
	position: relative;
	margin-bottom: calculate_vw(20);

	@media (max-width: $breakpoint1px) {
		padding: elemVwSize(5, $breakpoint1);
		padding-left: elemVwSize(25, $breakpoint1);
		padding-right: elemVwSize(10, $breakpoint1);
		margin-left: elemVwSize(40, $breakpoint1);
		margin-bottom: elemVwSize(20, $breakpoint1);
	}

	@media (max-width: $breakpoint2px) {
		padding: elemVwSize(5, $breakpoint2);
		padding-left: elemVwSize(25, $breakpoint2);
		padding-right: elemVwSize(10, $breakpoint2);
		margin-left: elemVwSize(40, $breakpoint2);
		margin-bottom: elemVwSize(20, $breakpoint2);
	}

	@media (max-width: $breakpoint3px) {
		padding: elemVwSize(5, $breakpoint3);
		padding-left: elemVwSize(25, $breakpoint3);
		padding-right: elemVwSize(10, $breakpoint3);
		margin-left: elemVwSize(40, $breakpoint3);
		margin-bottom: elemVwSize(20, $breakpoint3);
	}

	@media (max-width: $breakpoint4px) {
		padding: elemVwSize(5, $breakpoint4);
		padding-left: elemVwSize(25, $breakpoint4);
		padding-right: elemVwSize(10, $breakpoint4);
		margin-left: elemVwSize(40, $breakpoint4);
		margin-bottom: elemVwSize(20, $breakpoint4);
	}

	.mat-icon-button {
		width: auto;
		height: auto;
		font-family: "Source Sans Pro", sans-serif;
		font-size: calculate_vw(21);
		color: #F5F6FA;
		line-height: 1;
		position: static;
		border-radius: 0;
		@media (max-width: $breakpoint1px) {
			@include media-font-size(21, $breakpoint1);
		}

		@media (max-width: $breakpoint2px) {
			@include media-font-size(21, $breakpoint2);
		}

		@media (max-width: $breakpoint3px) {
			@include media-font-size(21, $breakpoint3);
		}

		@media (max-width: $breakpoint4px) {
			@include media-font-size(21, $breakpoint4);
		}
	}

	.mat-button-wrapper {
		box-sizing: initial;
	}

	.example-box__badge {
		position: absolute;
		top: 0;
		left: 0;
		display: block;
		transform: translate(-50%, -50%);
		border-radius: 50%;
		background-color: #F2EE71;
		border: calculate_vw(2) solid #fff;
		width: calculate_vw(20);
		height: calculate_vw(20);

		@media (max-width: $breakpoint1px) {
			border-width: elemVwSize(2, $breakpoint1);
			width: elemVwSize(20, $breakpoint1);
			height: elemVwSize(20, $breakpoint1);
		}

		@media (max-width: $breakpoint2px) {
			border-width: elemVwSize(2, $breakpoint2);
			width: elemVwSize(20, $breakpoint2);
			height: elemVwSize(20, $breakpoint2);
		}

		@media (max-width: $breakpoint3px) {
			border-width: elemVwSize(2, $breakpoint3);
			width: elemVwSize(20, $breakpoint3);
			height: elemVwSize(20, $breakpoint3);
		}

		@media (max-width: $breakpoint4px) {
			border-width: elemVwSize(2, $breakpoint4);
			width: elemVwSize(20, $breakpoint4);
			height: elemVwSize(20, $breakpoint4);
		}

		.mat-badge-content {
			left: 52%;
			top: 43%;
			transform: translate(-50%, -50%);
			background-color: transparent;
			color: #43425D;
			font-size: calculate_vw(16);
			line-height: normal;
			width: 100%;
			height: 100%;
			@media (max-width: $breakpoint1px) {
				@include media-font-size(16, $breakpoint1);
			}

			@media (max-width: $breakpoint2px) {
				@include media-font-size(16, $breakpoint2);
			}

			@media (max-width: $breakpoint3px) {
				@include media-font-size(16, $breakpoint3);
			}

			@media (max-width: $breakpoint4px) {
				@include media-font-size(16, $breakpoint4);
			}
		}
	}
}

.cdk-drag-placeholder {
	opacity: 0;
}

.form-container {
	display: flex;
	justify-content: space-between;
	position: relative;
	flex: 1;

	@media (max-width: $breakpoint5px) {
		display: none;
	}

	&::after {
		content: "";
		position: absolute;
		display: block;
		width: 1px;
		top: 0;
		height: 100%;
		left: 50%;
		transform: translateX(-50%);
		background-image: linear-gradient(45deg, #A4AFB7, #A4AFB7 50%, transparent 50%, transparent 100%);
		background-size: 1px 10px;
	}
}

.example-container {
	width: 50%;
	padding: calculate_vw(30);
	padding-top: calculate_vw(15);
	padding-bottom: 0;
	position: relative;

	&:first-of-type {
		padding-left: 0;
		z-index: 2;
	}

	&:last-of-type {
		padding-right: 0;
		z-index: 1;
	}

	.drag-drop-block {
		z-index: -1;

		span {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
		}
	}

	.cdk-drop-list {
		height: 100%;

		.cdk-drag {
			&:not(:last-of-type) {
				margin-bottom: calculate_vw(30);
			}
		}
	}
}

.drag-and-drop__item_dragging {
	width: 50px;
	height: 20px;
	overflow: hidden;
}

.example-box_preview {
	margin-left: 0;
}

/* Animate items as they're being sorted. */
.cdk-drop-dragging .cdk-drag {
	transition: transform 200ms cubic-bezier(0, 0, 0.2, 1);
}

/* Animate an item that has been dropped. */
.cdk-drag-animating {
	transition: transform 200ms cubic-bezier(0, 0, 0.2, 1);
}

// Click and drag handler
.drag-and-drop__item {
	position: relative;

	& > .cdk-drag-handle {
		position: absolute;
		left: 0;
		right: 1.7%;
		z-index: 200;
		cursor: pointer;

		svg {
			transform: translateX(-47%) translateY(7%);
			background-color: #fafafa;
			width: calculate_vw(20);
			height: calculate_vw(20);

			@media (max-width: $breakpoint1px) {
				width: elemVwSize(20, $breakpoint1);
				height: elemVwSize(20, $breakpoint1);
			}

			@media (max-width: $breakpoint2px) {
				width: elemVwSize(20, $breakpoint2);
				height: elemVwSize(20, $breakpoint2);
			}

			@media (max-width: $breakpoint3px) {
				width: elemVwSize(20, $breakpoint3);
				height: elemVwSize(20, $breakpoint3);
			}

			@media (max-width: $breakpoint4px) {
				width: elemVwSize(20, $breakpoint4);
				height: elemVwSize(20, $breakpoint4);
			}
		}
	}
}

.controls {
	margin-bottom: calculate_vw(25);
	display: flex;
	justify-content: flex-end;
	align-items: center;

	@media (max-width: $breakpoint1px) {
		margin-bottom: elemVwSize(25, $breakpoint1);
	}

	@media (max-width: $breakpoint2px) {
		margin-bottom: elemVwSize(25, $breakpoint2);
	}

	@media (max-width: $breakpoint3px) {
		margin-bottom: elemVwSize(25, $breakpoint3);
	}

	@media (max-width: $breakpoint4px) {
		margin-bottom: elemVwSize(25, $breakpoint4);
	}

	@media (max-width: $breakpoint5px) {
		margin-bottom: 15px;
	}
}

.add-user__btn {
	display: flex;
	align-items: center;
	@include font-size(19);
	color: #3B86FF;
	background-color: transparent;
	border: none;
	cursor: pointer;
	font-family: "Source Sans Pro", sans-serif;

	&:focus {
		outline: none;
	}

	@media (max-width: $breakpoint1px) {
		@include media-font-size(19, $breakpoint1);
	}

	@media (max-width: $breakpoint2px) {
		@include media-font-size(19, $breakpoint2);
	}

	@media (max-width: $breakpoint3px) {
		@include media-font-size(19, $breakpoint3);
	}

	@media (max-width: $breakpoint4px) {
		@include media-font-size(19, $breakpoint4);
	}

	@media (max-width: $breakpoint5px) {
		font-size: 16px;
	}
}

.add-user__icon {
	width: calculate_vw(34);
	height: calculate_vw(34);
	fill: #3B86FF;
	margin-left: calculate_vw(5);

	@media (max-width: $breakpoint1px) {
		width: elemVwSize(34, $breakpoint1);
		height: elemVwSize(34, $breakpoint1);
		margin-left: elemVwSize(5, $breakpoint1);
	}

	@media (max-width: $breakpoint2px) {
		width: elemVwSize(34, $breakpoint2);
		height: elemVwSize(34, $breakpoint2);
		margin-left: elemVwSize(5, $breakpoint2);
	}

	@media (max-width: $breakpoint3px) {
		width: elemVwSize(34, $breakpoint3);
		height: elemVwSize(34, $breakpoint3);
		margin-left: elemVwSize(5, $breakpoint3);
	}

	@media (max-width: $breakpoint4px) {
		width: elemVwSize(34, $breakpoint4);
		height: elemVwSize(34, $breakpoint4);
		margin-left: elemVwSize(5, $breakpoint4);
	}

	@media (max-width: $breakpoint5px) {
		width: 24px;
		height: 24px;
		margin-left: 5px;
	}
}

abbr[title] {
	border-bottom: none;
	text-decoration: none;
}

//.mat-tab-labels {
//
//  @media (max-width: $breakpoint5px) {
//    flex-wrap: wrap;
//    margin-left: -5px;
//  }
//
//  .mat-tab-label{
//    height: calculate_vw(48);
//    padding: 0 calculate_vw(24);
//    min-width: calculate_vw(160);
//    @include font-size(15);
//    color: #43425D;
//    opacity: 1;
//
//    @media (max-width: $breakpoint1px) {
//      height: auto;
//      padding: elemVwSize(24, $breakpoint1);
//      min-width: auto;
//      @include media-font-size(15, $breakpoint1);
//    }
//    @media (max-width: $breakpoint2px) {
//      padding: elemVwSize(24, $breakpoint2);
//      @include media-font-size(15, $breakpoint2);
//    }
//    @media (max-width: $breakpoint3px) {
//      padding: elemVwSize(24, $breakpoint3);
//      @include media-font-size(15, $breakpoint3);
//    }
//    @media (max-width: $breakpoint4px) {
//      padding: elemVwSize(24, $breakpoint4);
//      @include media-font-size(15, $breakpoint4);
//    }
//    @media (max-width: $breakpoint5px) {
//      padding: 5px 5px;
//      flex-basis: calc(50% - 5px);
//      max-width: calc(50% - 5px);
//      font-size: 15px;
//      border: 1px solid #43425D;
//      margin-bottom: 5px;
//      margin-left: 5px;
//
//      &.mat-tab-label-active {
//        border-color: $primary-color;
//        border-width: 2px;
//      }
//    }
//  }
//
//  .mat-ink-bar {
//    height: calculate_vw(2);
//
//    @media (max-width: $breakpoint1px) {
//      height: elemVwSize(2, $breakpoint1);
//    }
//    @media (max-width: $breakpoint2px) {
//      height: elemVwSize(2, $breakpoint2);
//    }
//    @media (max-width: $breakpoint3px) {
//      height: elemVwSize(2, $breakpoint3);
//    }
//    @media (max-width: $breakpoint4px) {
//      height: elemVwSize(2, $breakpoint4);
//    }
//    @media (max-width: $breakpoint5px) {
//      display: none;
//    }
//  }
//}

// Pagination styles
.content {

	.mat-paginator-container {
		min-height: calculate_vw(56);
		padding: 0 calculate_vw(8);

		@media (max-width: $breakpoint1px) {
			min-height: elemVwSize(56, $breakpoint1);
			padding: 0 elemVwSize(8, $breakpoint1);
		}

		@media (max-width: $breakpoint2px) {
			min-height: elemVwSize(56, $breakpoint2);
			padding: 0 elemVwSize(8, $breakpoint2);
		}

		@media (max-width: $breakpoint3px) {
			min-height: elemVwSize(56, $breakpoint3);
			padding: 0 elemVwSize(8, $breakpoint3);
		}

		@media (max-width: $breakpoint4px) {
			min-height: elemVwSize(56, $breakpoint4);
			padding: 0 elemVwSize(8, $breakpoint4);
		}

		@media (max-width: $breakpoint5px) {
			min-height: 45px;
			padding: 0;
		}
	}

	.mat-paginator, .mat-paginator-page-size .mat-select-trigger {
		@include font-size(12);

		@media (max-width: $breakpoint1px) {
			@include media-font-size(12, $breakpoint1);
		}

		@media (max-width: $breakpoint2px) {
			@include media-font-size(12, $breakpoint2);
		}

		@media (max-width: $breakpoint2px) {
			@include media-font-size(12, $breakpoint2);
		}

		@media (max-width: $breakpoint4px) {
			@include media-font-size(12, $breakpoint4);
		}

		@media (max-width: $breakpoint5px) {
			font-size: 12px;
		}
	}

	.mat-paginator-page-size-label {
		margin: 0 calculate_vw(4);

		@media (max-width: $breakpoint1px) {
			margin: 0 elemVwSize(4, $breakpoint1);
		}

		@media (max-width: $breakpoint2px) {
			margin: 0 elemVwSize(4, $breakpoint2);
		}

		@media (max-width: $breakpoint3px) {
			margin: 0 elemVwSize(4, $breakpoint3);
		}

		@media (max-width: $breakpoint4px) {
			margin: 0 elemVwSize(4, $breakpoint4);
		}

		@media (max-width: $breakpoint5px) {
			margin: 0 4px;
		}
	}

	.mat-paginator-range-label {
		margin: 0 calculate_vw(32) 0 calculate_vw(24);

		@media (max-width: $breakpoint1px) {
			margin: 0 elemVwSize(32, $breakpoint1) 0 elemVwSize(24, $breakpoint1);
		}

		@media (max-width: $breakpoint2px) {
			margin: 0 elemVwSize(32, $breakpoint2) 0 elemVwSize(24, $breakpoint2);
		}

		@media (max-width: $breakpoint3px) {
			margin: 0 elemVwSize(32, $breakpoint3) 0 elemVwSize(24, $breakpoint3);
		}

		@media (max-width: $breakpoint4px) {
			margin: 0 elemVwSize(32, $breakpoint4) 0 elemVwSize(24, $breakpoint4);
		}

		@media (max-width: $breakpoint5px) {
			margin: 0 32px 0 24px;
		}

		@media (max-width: 390px) {
			margin: 0 10px;
		}
	}

	.mat-paginator-icon {
		width: calculate_vw(28);
		fill: currentColor;

		@media (max-width: $breakpoint1px) {
			width: elemVwSize(28, $breakpoint1);
		}

		@media (max-width: $breakpoint2px) {
			width: elemVwSize(28, $breakpoint2);
		}

		@media (max-width: $breakpoint3px) {
			width: elemVwSize(28, $breakpoint3);
		}

		@media (max-width: $breakpoint4px) {
			width: 40px;
		}
	}

	.mat-paginator-range-actions {
		.mat-icon-button {
			width: calculate_vw(40);
			height: calculate_vw(40);
			line-height: calculate_vw(40);
			cursor: pointer;

			@media (max-width: $breakpoint1px) {
				width: elemVwSize(40, $breakpoint1);
				height: elemVwSize(40, $breakpoint1);
				line-height: elemVwSize(40, $breakpoint1);
			}

			@media (max-width: $breakpoint2px) {
				width: elemVwSize(40, $breakpoint2);
				height: elemVwSize(40, $breakpoint2);
				line-height: elemVwSize(40, $breakpoint2);
			}

			@media (max-width: $breakpoint3px) {
				width: elemVwSize(40, $breakpoint3);
				height: elemVwSize(40, $breakpoint3);
				line-height: elemVwSize(40, $breakpoint3);
			}

			@media (max-width: $breakpoint4px) {
				//width: elemVwSize(40, $breakpoint4);
				//height: elemVwSize(40, $breakpoint4);
				width: 40px;
				height: 40px;
				line-height: elemVwSize(40, $breakpoint4);
			}

			@media (max-width: $breakpoint5px) {
				width: 40px;
				height: 40px;
				line-height: 40px;
			}

			@media (max-width: 390px) {
				//width: 25px;
				//height: 25px;
				line-height: 25px;
			}
		}
	}

	.mat-select-arrow {
		border-left: calculate_vw(5) solid transparent;
		border-right: calculate_vw(5) solid transparent;
		border-top: calculate_vw(5) solid;
		margin: 0 calculate_vw(4);

		@media (max-width: $breakpoint1px) {
			border-left: elemVwSize(5, $breakpoint1) solid transparent;
			border-right: elemVwSize(5, $breakpoint1) solid transparent;
			border-top: elemVwSize(5, $breakpoint1) solid;
			margin: 0 elemVwSize(4, $breakpoint1);
		}

		@media (max-width: $breakpoint2px) {
			border-left: elemVwSize(5, $breakpoint2) solid transparent;
			border-right: elemVwSize(5, $breakpoint2) solid transparent;
			border-top: elemVwSize(5, $breakpoint2) solid;
			margin: 0 elemVwSize(4, $breakpoint2);
		}

		@media (max-width: $breakpoint3px) {
			border-left: elemVwSize(5, $breakpoint3) solid transparent;
			border-right: elemVwSize(5, $breakpoint3) solid transparent;
			border-top: elemVwSize(5, $breakpoint3) solid;
			margin: 0 elemVwSize(4, $breakpoint3);
		}

		@media (max-width: $breakpoint4px) {
			border-left: elemVwSize(5, $breakpoint4) solid transparent;
			border-right: elemVwSize(5, $breakpoint4) solid transparent;
			border-top: elemVwSize(5, $breakpoint4) solid;
			margin: 0 elemVwSize(4, $breakpoint4);
		}

		@media (max-width: $breakpoint5px) {
			border-left: 5px solid transparent;
			border-right: 5px solid transparent;
			border-top: 5px solid;
			margin: 0 4px;
		}
	}

	.mat-form-field-appearance-legacy .mat-form-field-underline {
		height: 1px;

		@media (min-width: 1921px) {
			height: calculate_vw(1);
		}
	}

	.mat-paginator-page-size-select {
		margin: calculate_vw(6) calculate_vw(4) 0 calculate_vw(4);
		width: calculate_vw(56);

		@media (max-width: $breakpoint1px) {
			margin: elemVwSize(6, $breakpoint1) elemVwSize(4, $breakpoint1) 0 elemVwSize(4, $breakpoint1);
			width: elemVwSize(56, $breakpoint1);
		}

		@media (max-width: $breakpoint2px) {
			margin: elemVwSize(6, $breakpoint2) elemVwSize(4, $breakpoint2) 0 elemVwSize(4, $breakpoint2);
			width: elemVwSize(56, $breakpoint2);
		}

		@media (max-width: $breakpoint3px) {
			margin: elemVwSize(6, $breakpoint3) elemVwSize(4, $breakpoint3) 0 elemVwSize(4, $breakpoint3);
			width: elemVwSize(56, $breakpoint3);
		}

		@media (max-width: $breakpoint4px) {
			margin: elemVwSize(6, $breakpoint4) elemVwSize(4, $breakpoint4) 0 elemVwSize(4, $breakpoint4);
			width: elemVwSize(56, $breakpoint4);
		}

		@media (max-width: $breakpoint5px) {
			margin: 6px 4px 0 4px;
			width: 40px;
		}
	}
}

.main-content__wrapper {
	.mat-paginator-container {
		min-height: calculate_vw(56);
		padding: 0 calculate_vw(8);

		@media (max-width: $breakpoint1px) {
			min-height: elemVwSize(56, $breakpoint1);
			padding: 0 elemVwSize(8, $breakpoint1);
		}

		@media (max-width: $breakpoint2px) {
			min-height: elemVwSize(56, $breakpoint2);
			padding: 0 elemVwSize(8, $breakpoint2);
		}

		@media (max-width: $breakpoint3px) {
			min-height: elemVwSize(56, $breakpoint3);
			padding: 0 elemVwSize(8, $breakpoint3);
		}

		@media (max-width: $breakpoint4px) {
			min-height: elemVwSize(56, $breakpoint4);
			padding: 0 elemVwSize(8, $breakpoint4);
		}

		@media (max-width: $breakpoint5px) {
			min-height: 45px;
			padding: 0;
		}
	}

	.mat-paginator, .mat-paginator-page-size .mat-select-trigger {
		@include font-size(12);

		@media (max-width: $breakpoint1px) {
			@include media-font-size(12, $breakpoint1);
		}

		@media (max-width: $breakpoint2px) {
			@include media-font-size(12, $breakpoint2);
		}

		@media (max-width: $breakpoint2px) {
			@include media-font-size(12, $breakpoint2);
		}

		@media (max-width: $breakpoint4px) {
			@include media-font-size(12, $breakpoint4);
		}

		@media (max-width: $breakpoint5px) {
			font-size: 12px;
		}
	}

	.mat-paginator-page-size-label {
		margin: 0 calculate_vw(4);

		@media (max-width: $breakpoint1px) {
			margin: 0 elemVwSize(4, $breakpoint1);
		}

		@media (max-width: $breakpoint2px) {
			margin: 0 elemVwSize(4, $breakpoint2);
		}

		@media (max-width: $breakpoint3px) {
			margin: 0 elemVwSize(4, $breakpoint3);
		}

		@media (max-width: $breakpoint4px) {
			margin: 0 elemVwSize(4, $breakpoint4);
		}

		@media (max-width: $breakpoint5px) {
			margin: 0 4px;
		}
	}

	.mat-paginator-range-label {
		margin: 0 calculate_vw(32) 0 calculate_vw(24);

		@media (max-width: $breakpoint1px) {
			margin: 0 elemVwSize(32, $breakpoint1) 0 elemVwSize(24, $breakpoint1);
		}

		@media (max-width: $breakpoint2px) {
			margin: 0 elemVwSize(32, $breakpoint2) 0 elemVwSize(24, $breakpoint2);
		}

		@media (max-width: $breakpoint3px) {
			margin: 0 elemVwSize(32, $breakpoint3) 0 elemVwSize(24, $breakpoint3);
		}

		@media (max-width: $breakpoint4px) {
			margin: 0 elemVwSize(32, $breakpoint4) 0 elemVwSize(24, $breakpoint4);
		}

		@media (max-width: $breakpoint5px) {
			margin: 0 32px 0 24px;
		}

		@media (max-width: 390px) {
			margin: 0 10px;
		}
	}

	.mat-paginator-icon {
		width: calculate_vw(28);
		fill: currentColor;

		@media (max-width: $breakpoint1px) {
			width: elemVwSize(28, $breakpoint1);
		}

		@media (max-width: $breakpoint2px) {
			width: elemVwSize(28, $breakpoint2);
		}

		@media (max-width: $breakpoint3px) {
			width: elemVwSize(28, $breakpoint3);
		}

		@media (max-width: $breakpoint4px) {
			width: elemVwSize(28, $breakpoint4);
		}

		@media (max-width: $breakpoint4px) {
			width: 40px;
		}
	}

	.mat-paginator-range-actions {
		.mat-icon-button {
			width: calculate_vw(40);
			height: calculate_vw(40);
			line-height: calculate_vw(40);
			cursor: pointer;

			@media (max-width: $breakpoint1px) {
				width: elemVwSize(40, $breakpoint1);
				height: elemVwSize(40, $breakpoint1);
				line-height: elemVwSize(40, $breakpoint1);
			}

			@media (max-width: $breakpoint2px) {
				width: elemVwSize(40, $breakpoint2);
				height: elemVwSize(40, $breakpoint2);
				line-height: elemVwSize(40, $breakpoint2);
			}

			@media (max-width: $breakpoint3px) {
				width: elemVwSize(40, $breakpoint3);
				height: elemVwSize(40, $breakpoint3);
				line-height: elemVwSize(40, $breakpoint3);
			}

			@media (max-width: $breakpoint4px) {
				//width: elemVwSize(40, $breakpoint4);
				//height: elemVwSize(40, $breakpoint4);
				width: 40px;
				height: 40px;
				line-height: elemVwSize(40, $breakpoint4);
			}

			@media (max-width: $breakpoint5px) {
				width: 40px;
				height: 40px;
				line-height: 40px;
			}

			@media (max-width: 390px) {
				//width: 40px;
				//height: 25px;
				line-height: 25px;
			}
		}
	}

	.mat-select-arrow {
		border-left: calculate_vw(5) solid transparent;
		border-right: calculate_vw(5) solid transparent;
		border-top: calculate_vw(5) solid;
		margin: 0 calculate_vw(4);

		@media (max-width: $breakpoint1px) {
			border-left: elemVwSize(5, $breakpoint1) solid transparent;
			border-right: elemVwSize(5, $breakpoint1) solid transparent;
			border-top: elemVwSize(5, $breakpoint1) solid;
			margin: 0 elemVwSize(4, $breakpoint1);
		}

		@media (max-width: $breakpoint2px) {
			border-left: elemVwSize(5, $breakpoint2) solid transparent;
			border-right: elemVwSize(5, $breakpoint2) solid transparent;
			border-top: elemVwSize(5, $breakpoint2) solid;
			margin: 0 elemVwSize(4, $breakpoint2);
		}

		@media (max-width: $breakpoint3px) {
			border-left: elemVwSize(5, $breakpoint3) solid transparent;
			border-right: elemVwSize(5, $breakpoint3) solid transparent;
			border-top: elemVwSize(5, $breakpoint3) solid;
			margin: 0 elemVwSize(4, $breakpoint3);
		}

		@media (max-width: $breakpoint4px) {
			border-left: elemVwSize(5, $breakpoint4) solid transparent;
			border-right: elemVwSize(5, $breakpoint4) solid transparent;
			border-top: elemVwSize(5, $breakpoint4) solid;
			margin: 0 elemVwSize(4, $breakpoint4);
		}

		@media (max-width: $breakpoint5px) {
			border-left: 5px solid transparent;
			border-right: 5px solid transparent;
			border-top: 5px solid;
			margin: 0 4px;
		}
	}

	.mat-form-field-appearance-legacy .mat-form-field-underline {
		height: 1px;

		@media (min-width: 1921px) {
			height: calculate_vw(1);
		}
	}

	.mat-paginator-page-size-select {
		margin: calculate_vw(6) calculate_vw(4) 0 calculate_vw(4);
		width: calculate_vw(56);

		@media (max-width: $breakpoint1px) {
			margin: elemVwSize(6, $breakpoint1) elemVwSize(4, $breakpoint1) 0 elemVwSize(4, $breakpoint1);
			width: elemVwSize(56, $breakpoint1);
		}

		@media (max-width: $breakpoint2px) {
			margin: elemVwSize(6, $breakpoint2) elemVwSize(4, $breakpoint2) 0 elemVwSize(4, $breakpoint2);
			width: elemVwSize(56, $breakpoint2);
		}

		@media (max-width: $breakpoint3px) {
			margin: elemVwSize(6, $breakpoint3) elemVwSize(4, $breakpoint3) 0 elemVwSize(4, $breakpoint3);
			width: elemVwSize(56, $breakpoint3);
		}

		@media (max-width: $breakpoint4px) {
			margin: elemVwSize(6, $breakpoint4) elemVwSize(4, $breakpoint4) 0 elemVwSize(4, $breakpoint4);
			width: elemVwSize(56, $breakpoint4);
		}

		@media (max-width: $breakpoint5px) {
			margin: 6px 4px 0 4px;
			width: 40px;
		}
	}
}

// Download button
.download {
	background-color: transparent;
	border: 0;
	padding: 0;
	cursor: pointer;

	&:focus {
		outline: none;
	}
}

.download__icon {
	width: calculate_vw(25);
	height: calculate_vw(25);
	@media screen and (max-width: 767px) {
		width: 20px;
		height: 20px;
	}
}

// Col filter
.col-filter {
	display: flex;
	align-items: center;
	justify-content: center;

	select {
		margin-left: calculate_vw(10);
	}
}

// Save file
.save-file {
	display: flex;
	justify-content: space-between;
	align-items: baseline;
	margin-top: calculate_vw(30);

	@media (max-width: $breakpoint1px) {
		margin-top: elemVwSize(30, $breakpoint1);
		margin-left: elemVwSize(-10, $breakpoint1);
	}

	@media (max-width: $breakpoint2px) {
		margin-top: elemVwSize(30, $breakpoint2);
		margin-left: elemVwSize(-10, $breakpoint2);
	}

	@media (max-width: $breakpoint3px) {
		margin-top: elemVwSize(30, $breakpoint3);
		margin-left: elemVwSize(-10, $breakpoint3);
	}

	@media (max-width: $breakpoint4px) {
		margin-top: elemVwSize(30, $breakpoint4);
		margin-left: elemVwSize(-10, $breakpoint4);
	}

	@media (max-width: $breakpoint5px) {
		margin-left: 0;
		margin-top: 25px;
	}

	&__group {
		display: flex;
		flex-wrap: wrap;
		flex: 1;
		justify-content: flex-end;
	}

	&__btn-wrapper {
		flex-shrink: 0;
		margin-top: calculate_vw(5);

		.form-action {
			margin-left: 0;
		}

		@media (max-width: $breakpoint1px) {
			margin-top: elemVwSize(5, $breakpoint1);
		}

		@media (max-width: $breakpoint2px) {
			margin-top: elemVwSize(5, $breakpoint2);
		}

		@media (max-width: $breakpoint3px) {
			margin-top: elemVwSize(5, $breakpoint3);
		}

		@media (max-width: $breakpoint4px) {
			margin-top: elemVwSize(5, $breakpoint4);
		}

		@media (max-width: $breakpoint5px) {
			max-width: none;
			width: 100px;
			margin-right: 20px;
			margin-left: -10px;
		}
	}

	&__input-wrapper {
		width: 100%;

		.site-input {
			width: 100%;
			padding: calculate_vw(10);
			line-height: 1;
			box-sizing: border-box;

			@media (max-width: $breakpoint1px) {
				padding: elemVwSize(10, $breakpoint1);
			}

			@media (max-width: $breakpoint2px) {
				padding: elemVwSize(10, $breakpoint2);
			}

			@media (max-width: $breakpoint3px) {
				padding: elemVwSize(10, $breakpoint3);
			}

			@media (max-width: $breakpoint4px) {
				padding: elemVwSize(10, $breakpoint4);
			}

			@media (max-width: $breakpoint5px) {
				padding: 8px 10px;
				font-size: 15px;
			}
		}
	}

	&_file {
		align-items: center;
		margin-left: calculate_vw(-10);

		.save-file__btn-wrapper {
			width: 100%;
			max-width: calculate_vw(112);
			flex-shrink: 0;
			margin-right: calculate_vw(12);

			.form-action {
				margin-left: 0;
			}

			@media (max-width: $breakpoint1px) {
				max-width: elemVwSize(112, $breakpoint1);
				margin-right: elemVwSize(10, $breakpoint1);
			}
			@media (max-width: $breakpoint2px) {
				max-width: elemVwSize(112, $breakpoint2);
				margin-right: elemVwSize(10, $breakpoint2);
			}
			@media (max-width: $breakpoint3px) {
				max-width: elemVwSize(112, $breakpoint3);
				margin-right: elemVwSize(10, $breakpoint3);
			}
			@media (max-width: $breakpoint4px) {
				max-width: elemVwSize(112, $breakpoint4);
				margin-right: elemVwSize(10, $breakpoint4);
			}
			@media (max-width: $breakpoint5px) {
				max-width: none;
				width: 100px;
				margin-right: 20px;
				margin-left: -10px;
			}
		}

		.save-file__input-wrapper {
			flex: 1;
		}
	}
}


.mat-autocomplete-panel {
	max-height: 216px !important;
	@media screen and (min-width: 2000px) {
		max-height: 256px !important;
	}
}

.mat-option {

	@include font-size(16);
	line-height: 1 !important;
	margin: calculate_vw(4) 0;
	@media screen and (max-width: $breakpoint1px) {
		font-size: 16px;
	}

	&.grey {
		background: gainsboro !important;
	}
}

.mat-tooltip {
	@include font-size(16);
}


// Mat select
.mat-select-panel {

	.mat-option {
		@include font-size(18);

		@media (max-width: $breakpoint1px) {
			line-height: 2.5 !important;
			height: auto !important;
		}

		.mat-option-text {
			@media (max-width: $breakpoint1px) {
				@include media-font-size(16, $breakpoint1);
			}

			@media (max-width: $breakpoint2px) {
				@include media-font-size(16, $breakpoint2);
			}

			@media (max-width: $breakpoint3px) {
				@include media-font-size(16, $breakpoint3);
			}

			@media (max-width: $breakpoint4px) {
				@include media-font-size(16, $breakpoint4);
			}

			@media (max-width: $breakpoint5px) {
				font-size: 14px;
			}
		}
	}
}

.mat-dialog-container {
	padding: elemVwSize(24, 1920) !important;
	@media (max-width: 1919px) {
		padding: 24px !important;
	}
	@media (max-width: 440px) {
		padding: 10px !important;
	}
}

// Material tab nav bar
.mobile-popup {

	.form-fieldset {
		height: 100%;
	}

	@media (max-width: $breakpoint5px) {
		margin-top: 0 !important;
		margin-left: 0 !important;
		left: 0;
		right: 0;
		bottom: 0;
		top: 70px;
		position: absolute !important;
		max-width: 100% !important;

		.mat-dialog-container {
			border-radius: 0;
		}

		&_audit-info {

			.mat-dialog-container {
				padding-left: 10px;
				padding-right: 10px;
				padding-bottom: 10px;
				display: flex;
				flex-direction: column;
			}
		}
	}

	.mat-dialog-container {
		box-shadow: none !important;
	}
	&.with-shadow {
		box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
	}
}

.popup-scrollable  {
	overflow-y: auto;

	max-height: 89vh;
}

.no-padding {
	.mat-dialog-container {
		padding: 0 !important;
	}
}

.document-popup {
	.mat-dialog-container {
		padding: 1vw;
	}

	.document-menu {
		li {
			margin: 0.5vw 0;
			padding: 0.5vw 0;
			cursor: pointer;
			transition: all 0.3s linear;
			font-weight: 600;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			color: $form-primary-text-color;

			&:before {
				content: '';
				display: inline-block;
				margin-right: 0.5vw;
				width: 1vw;
				height: 1vw;
				background-size: cover;
				@media screen and (max-width: 1200px) {
					width: 15px;
					height: 15px;
				}
			}
		}

		&__download {
			&:before {
				background: url("./assets/img/svg-icons/download-ico.svg") center no-repeat;
			}
		}

		&__confirm {
			&:before {
				background: url("./assets/img/svg-icons/confirmation-ico.svg") center no-repeat;
			}
		}

		&__reviewed {
			&:before {
				background: url("./assets/img/svg-icons/view-ico.svg") center no-repeat;
			}
		}

		li.document-menu__all {
			color: #fff;
			background: $accent-color;
			justify-content: center;
			margin: 0.5vw -12px 0;

			&:before {
				display: none !important;
			}
		}
	}
}

.mobile-popup__overlay {
	//background-color: rgba(#000, .65);
}

.close-popup {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: transparent;
	padding: 10px;
	border: 0;
	//padding: 0;
	position: absolute;
	right: 10px;
	top: 10px;
	z-index: 10;

	&:focus {
		outline: none;
	}

	@media (min-width: 768px) {
		display: none;
	}
}

.close-popup__icon {
	width: 18px;
	height: 18px;
	fill: #43425D;
	pointer-events: none;
}

.ltd-info-frame {

	.form-fieldset {
		flex: 1;
	}

	.form-content {
		position: absolute;
		left: calculate_vw(10);
		right: calculate_vw(10);
		top: calculate_vw(25);
		bottom: calculate_vw(10);
		display: flex;
		flex-direction: column;

		@media (max-width: $breakpoint2px) {
			top: elemVwSize(25, $breakpoint2);
		}

		@media (max-width: $breakpoint3px) {
			top: elemVwSize(25, $breakpoint3);
		}

		@media (max-width: $breakpoint5px) {
			top: 20px;
			left: 5px;
			right: 5px;
			bottom: 5px;
		}
	}

	.form-horizontal {
		flex: 1;
		display: flex;
		flex-direction: column;

		dynamic-material-form {
			flex: 1;
			display: flex;
			flex-direction: column;
			position: relative;
		}

		dynamic-material-form-control {

			& > div {
				position: absolute;
				left: 0;
				top: 0;
				right: 0;
				bottom: 0;
				display: flex;
				flex-direction: column;
			}
		}

		dynamic-material-textarea {
			flex: 1;
			display: flex;
			flex-direction: column;
		}

		.mat-form-field {
			flex: 1;
			display: flex;
			flex-direction: column;
		}

		.mat-form-field-wrapper {
			flex: 1;
			display: flex;
			flex-direction: column;
		}

		.mat-form-field-flex {
			flex: 1;
			display: flex;
			flex-direction: column;
		}

		.mat-form-field-infix {
			flex-direction: column;
		}
	}
}

.ltd-form {

	.form-fieldset textarea.mat-input-element {
		min-height: calculate_vw(317);

		@media (max-width: $breakpoint1px) {
			min-height: elemVwSize(350, $breakpoint1);
		}
	}
}

//Cropper popup
.cropper-popup {
	@media (min-width: 1921px) {
		width: calculate_vw(600);
		max-width: none !important;
	}

	@media (max-width: 630px) {
		width: 100% !important;
		.mat-dialog-container {
			width: calc(100% - 20px);
			margin: 0 auto;
			height: auto;
			padding: 10px;
		}

		.dialog-crop-container {
			margin: 0;
			padding: 0;
		}

		.mat-dialog-actions {
			padding: 0;
			min-height: auto;
			margin-bottom: 0;
		}
	}
}


// simple notifications custom styles
.simple-notification {
	&.custom-info.info {
		background: #0867ff;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;

		&:after {
			content: '';
		}

		.sn-progress-loader {
			top: 100%;

			span {
				background: #0867ff;
				border-bottom-left-radius: 10px;
				border-bottom-right-radius: 10px;
			}
		}
	}
}


//material tabs
.mat-tab-links, .mat-tab-labels {
	@media (max-width: $breakpoint5px) {
		margin-left: -5px;
		flex-wrap: wrap;
	}

	.mat-tab-link, .mat-tab-label {
		height: calculate_vw(36);
		padding: 0 calculate_vw(24);
		min-width: calculate_vw(160);
		@include font-size(15);
		color: #43425D;
		opacity: 1;
		background: transparent;
		position: relative;
		overflow: hidden;
		z-index: 1;

		b, .mat-tab-label-content {
			position: relative;
			z-index: 2;
			font-weight: 700;
			text-overflow: ellipsis !important;
		}

		&:after {
			transition: all 0.3s linear;
			z-index: 1;
			content: '';
			display: inline-block;
			border-bottom: elemVwSize(36, 1440) solid #A4AFB7;
			border-left: 0 solid transparent;
			border-right: elemVwSize(18, 1440) solid transparent;
			height: 0;
			width: 100%;
			position: absolute;
			border-top-left-radius: 5px;
			border-top-right-radius: 25px;
			@media (max-width: $breakpoint1px) {
				border-bottom: 36px solid #A4AFB7;
				border-right: 18px solid transparent;
			}
		}

		&:not(:first-child) {
			margin-left: -7px;
		}


		.mat-ripple-element {
			z-index: 2;
			display: none;
		}

		&.mat-tab-label-active {
			z-index: 3;

			&:after {
				animation: tabs-change 0.5s linear 1;
				border-bottom: elemVwSize(36, 1440) solid #F2EE71;
				border-left: 0 solid transparent;
				border-right: elemVwSize(18, 1440) solid transparent;
				@media (max-width: $breakpoint1px) {
					border-bottom: 36px solid #F2EE71;
					border-right: 18px solid transparent;
				}
			}
		}

		@keyframes tabs-change {
			0% {
				border-bottom: none;
			}
			100% {
				border-bottom: elemVwSize(36, 1440) solid #F2EE71;
				//@media (max-width: $breakpoint1px) {
				//  border-bottom: 36px solid #F2EE71;
				//}
			}
		}

		@media (max-width: $breakpoint1px) {
			height: auto;
			padding: elemVwSize(9, $breakpoint1) elemVwSize(24, $breakpoint1);
			min-width: auto;
			@include media-font-size(15, $breakpoint1);
		}
		@media (max-width: $breakpoint2px) {
			padding: elemVwSize(9, $breakpoint1) elemVwSize(24, $breakpoint1);
			@include media-font-size(15, $breakpoint2);
		}
		@media (max-width: $breakpoint3px) {
			padding: elemVwSize(9, $breakpoint1) elemVwSize(24, $breakpoint1);
			@include media-font-size(15, $breakpoint3);
		}
		@media (max-width: $breakpoint4px) {
			padding: elemVwSize(9, $breakpoint1) elemVwSize(24, $breakpoint1);
			@include media-font-size(15, $breakpoint4);
		}
		@media (max-width: $breakpoint5px) {
			padding: 7px 24px 7px 20px;
			font-size: 15px;
		}

		@media screen and (max-width: 480px) {
			flex-shrink: 0;
			flex-grow: 1;
			justify-content: flex-start;
			min-width: 100px;
			padding: 4px 14px 4px 5px;
			margin-top: 10px;
			width: 50%;
			font-size: 14px;
			b, .mat-tab-label-content {
				overflow: hidden;
				text-overflow: clip;
				padding-left: 12px;
			}

			&.mat-tab-label-active {
				&:after {
				}
			}
		}

	}

	.mat-ink-bar {
		display: none !important;
		height: calculate_vw(2);

		@media (max-width: $breakpoint1px) {
			height: elemVwSize(2, $breakpoint1);
		}
		@media (max-width: $breakpoint2px) {
			height: elemVwSize(2, $breakpoint2);
		}
		@media (max-width: $breakpoint3px) {
			height: elemVwSize(2, $breakpoint3);
		}
		@media (max-width: $breakpoint4px) {
			height: elemVwSize(2, $breakpoint4);
		}
		@media (max-width: $breakpoint5px) {
			display: none;
		}
	}
}

.mat-tab-list {
	.mat-ink-bar {
		display: none;
	}
}

.text-overflow {
	p {
		line-height: elemVwSize(16, 1440);
		overflow: hidden;
		height: elemVwSize(16, 1440);
		-ms-text-overflow: ellipsis;
		text-overflow: ellipsis;
		@media (max-width: $breakpoint1px) {
			height: 16px;
			line-height: 16px;
		}
	}
}

.overlay_table{
	width: 80% !important;
}

.cdk-overlay-pane {
	//@media (max-width: 440px) {
	//	padding: 0 5vw;
	//	background-color: #fff;
	//	max-width: 100% !important;
	//	width: 100%;
	//}
	#notificationAlert {
		@media (max-width: 440px) {
			width: 73vw !important;
			margin: 0 -10px;
		}
	}
	.save-file_file .save-file__btn-wrapper {
		@media (max-width: $breakpoint5px) {
			margin-left: 2px;
		}
	}
}

.mat-option {
	span {
		display: flex;
		padding: 0 0.2em;
		font-weight: bold;
	}
}
