@function calculate_vw ($size) {
  $vw_size: ($size * 100) / 1600;
  @return $vw_size * 1vw;
}

@function elemVwSize ($size, $viewport-width) {
  $vw_size: ($size * 100) / $viewport-width;
  @return $vw_size * 1vw;
}

@mixin font-size($size) {
  font-size: $size * 1px;
  font-size: calculate_vw($size);
}

@mixin media-font-size($size, $viewport-width) {
  font-size: $size * 1px;
  font-size: elemVwSize($size, $viewport-width);
}

@mixin media-right($size, $viewport-width) {
  font-size: $size * 1px;
  font-size: elemVwSize($size, $viewport-width);
}
