@import "../variables/colors";
@import "../variables/calculate_vw";
@import "../variables/breakpoints";
@import "../../../app/@view/partial/form-button/form-button.component";

.name_input {
	margin-bottom: calculate_vw(30);
}
.visually-hidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	white-space: nowrap;
	width: 1px;
}



.form-fieldset {

	.dirty {
		.mat-form-field-infix {
			input, .mat-select, textarea {
				border: 1px solid #A4AFB7 !important;
			}
		}
		.mat-radio-button.mat-radio-checked, .mat-checkbox-layout {
			border: 1px solid #A4AFB7 !important;
		}
	}
	input, textarea {
		border: 1px solid #A4AFB7;
		transition: all 0.3s linear;
	}
	.mat-select, .mat-radio-button, .mat-checkbox-layout {
		border: 1px solid transparent;
	}

	// Radio buttons
	.mat-radio-group {
		margin-left: calculate_vw(-20);
		margin-bottom: calculate_vw(-10);
		display: inline-flex;
		outline: none;
		@media (max-width: $breakpoint1px) {
			margin-left: elemVwSize(-20, $breakpoint1);
			margin-bottom: elemVwSize(-10, $breakpoint1);
		}

		@media (max-width: $breakpoint2px) {
			margin-left: elemVwSize(-20, $breakpoint2);
			margin-bottom: elemVwSize(-10, $breakpoint2);
			flex-wrap: wrap;
		}

		@media (max-width: $breakpoint3px) {
			margin-left: elemVwSize(-20, $breakpoint3);
			margin-bottom: elemVwSize(-10, $breakpoint3);
		}

		@media (max-width: $breakpoint4px) {
			margin-left: elemVwSize(-20, $breakpoint4);
			margin-bottom: elemVwSize(-10, $breakpoint4);
		}

		@media (max-width: $breakpoint5px) {
			margin-left: -10px;
			margin-bottom: -10px;
			flex-wrap: wrap;
		}
	}

	.mat-radio-button {
		margin-left: calculate_vw(20);
		margin-bottom: calculate_vw(10);

		@media (max-width: $breakpoint1px) {
			margin-left: elemVwSize(20, $breakpoint1);
			margin-bottom: elemVwSize(10, $breakpoint1);
		}

		@media (max-width: $breakpoint2px) {
			margin-left: elemVwSize(20, $breakpoint2);
			margin-bottom: elemVwSize(10, $breakpoint2);
		}

		@media (max-width: $breakpoint3px) {
			margin-left: elemVwSize(20, $breakpoint3);
			margin-bottom: elemVwSize(10, $breakpoint3);
		}

		@media (max-width: $breakpoint4px) {
			margin-left: elemVwSize(20, $breakpoint4);
			margin-bottom: elemVwSize(10, $breakpoint4);
		}

		@media (max-width: $breakpoint5px) {
			margin-left: 10px;
			margin-bottom: 10px;
		}

		.mat-radio-container {
			display: none;
		}

		.mat-radio-label-content {
			padding-left: 0;

			span {
				&:first-child {
					display: none;
				}

				&:last-child {
					display: inline-block;
					border: 1px solid #A4AFB7;
					background-color: #F2F3F3;
					color: rgba($form-primary-text-color, 0.4);
					@include font-size(20);
					width: auto;
					min-width: calculate_vw(50);
					text-align: center;
					padding: calculate_vw(4) calculate_vw(5);

					@media (max-width: $breakpoint1px) {
						@include media-font-size(20, $breakpoint1);
						min-width: elemVwSize(50, $breakpoint1);
						padding: elemVwSize(4, $breakpoint1) elemVwSize(5, $breakpoint1);
					}

					@media (max-width: $breakpoint2px) {
						@include media-font-size(20, $breakpoint2);
						min-width: elemVwSize(50, $breakpoint2);
						padding: elemVwSize(4, $breakpoint2) elemVwSize(5, $breakpoint2);
					}

					@media (max-width: $breakpoint3px) {
						@include media-font-size(20, $breakpoint3);
						min-width: elemVwSize(50, $breakpoint3);
						padding: elemVwSize(4, $breakpoint3) elemVwSize(5, $breakpoint3);
					}

					@media (max-width: $breakpoint4px) {
						@include media-font-size(20, $breakpoint4);
						min-width: elemVwSize(50, $breakpoint4);
						padding: elemVwSize(4, $breakpoint4) elemVwSize(5, $breakpoint4);
					}

					@media (max-width: $breakpoint5px) {
						font-size: 16px;
						min-width: 40px;
						padding: 4px 5px;
					}
				}
			}
		}

		&.mat-radio-checked {
			.mat-radio-label-content {

				span {

					&:last-child {
						background-color: #F2EE71;
						color: $form-primary-text-color;
					}
				}
			}
		}
	}

	// Form label
	.form-label {
		@include font-size(17);
		display: inline-block;
		width: 100%;
		max-width: calculate_vw(100);
		margin-right: calculate_vw(15);
		font-family: "Source Sans Pro", sans-serif;
		font-weight: 700;
		//font-weight: 400;
		color: $form-primary-text-color;

		&_full-width {
			max-width: 100%;
			margin-right: 0;
			width: 100%;

			&_inline {
				display: inline;
				margin-right: calculate_vw(5);
			}
		}

		&_mb {
			margin-bottom: calculate_vw(13);
			@media (max-width: $breakpoint1px) {
				margin-bottom: elemVwSize(13, $breakpoint1);
			}

			@media (max-width: $breakpoint2px) {
				margin-bottom: elemVwSize(13, $breakpoint2);
			}

			@media (max-width: $breakpoint3px) {
				margin-bottom: elemVwSize(13, $breakpoint3);
			}

			@media (max-width: $breakpoint4px) {
				margin-bottom: elemVwSize(13, $breakpoint4);
			}

			@media (max-width: $breakpoint5px) {
				margin-bottom: 10px;
			}
		}

		&_mt {
			margin-top: calculate_vw(13);

			@media (max-width: $breakpoint1px) {
				margin-top: elemVwSize(13, $breakpoint1);
			}

			@media (max-width: $breakpoint2px) {
				margin-top: elemVwSize(13, $breakpoint2);
			}

			@media (max-width: $breakpoint3px) {
				margin-top: elemVwSize(13, $breakpoint3);
			}

			@media (max-width: $breakpoint4px) {
				margin-top: elemVwSize(13, $breakpoint4);
			}

			@media (max-width: $breakpoint5px) {
				margin-top: 10px;
			}
		}

		@media (max-width: $breakpoint1px) {
			@include media-font-size(16, $breakpoint1);
			max-width: elemVwSize(100, $breakpoint1);

			&_full-width {
				max-width: none;
				margin-right: 0;
				width: 100%;
			}
		}

		@media (max-width: $breakpoint2px) {
			@include media-font-size(16, $breakpoint2);
			max-width: elemVwSize(100, $breakpoint2);

			&_full-width {
				max-width: none;
				margin-right: 0;
				width: 100%;
			}
		}

		@media (max-width: $breakpoint3px) {
			@include media-font-size(16, $breakpoint3);
			max-width: elemVwSize(100, $breakpoint3);

			&_full-width {
				max-width: none;
				margin-right: 0;
				width: 100%;
			}
		}

		@media (max-width: $breakpoint4px) {
			@include media-font-size(16, $breakpoint4);
			max-width: elemVwSize(100, $breakpoint4);

			&_full-width {
				max-width: none;
				margin-right: 0;
				width: 100%;
			}
		}

		@media (max-width: $breakpoint5px) {
			font-size: 15px;
			width: 100px;
			max-width: none;
			flex-shrink: 0;
			margin-right: 10px;
			margin-bottom: 5px;

			&_full-width {
				max-width: none;
				margin-right: 0;
				width: 100%;
			}
		}

		&--subtitle {
			@include font-size(26);
			line-height: 1.4;
			margin-top: 1.5vw;

			@media (max-width: $breakpoint1px) {
				@include media-font-size(26, $breakpoint1);
			}

			@media (max-width: $breakpoint2px) {
				@include media-font-size(26, $breakpoint2);
			}

			@media (max-width: $breakpoint3px) {
				@include media-font-size(26, $breakpoint3);
			}

			@media (max-width: $breakpoint4px) {
				@include media-font-size(26, $breakpoint4);
			}

			@media (max-width: $breakpoint5px) {
				font-size: 26px;
			}
		}
	}

	// Form item
	.form-item {
		&:not(:last-of-type) {

			.form-fieldset {
				margin-bottom: calculate_vw(50);

				@media (max-width: $breakpoint1px) {
					margin-bottom: elemVwSize(50, $breakpoint1);
				}

				@media (max-width: $breakpoint2px) {
					margin-bottom: elemVwSize(50, $breakpoint2);
				}

				@media (max-width: $breakpoint3px) {
					margin-bottom: elemVwSize(50, $breakpoint3);
				}

				@media (max-width: $breakpoint4px) {
					margin-bottom: elemVwSize(50, $breakpoint4);
				}
			}
		}
	}

	// Material form input and label
	.mat-form-field {
		width: 100%;
		display: block;
		color: $form-primary-text-color;
		//margin-bottom: 15px;
		//color: $warning-color;
	}

	.warning-label {
		color: $warning-color !important;

		.mat-form-field-label {
			color: $warning-color !important;
		}
	}

	.accept-label {
		color: $accent-color;

		.mat-form-field-label {
			color: $accent-color;
		}
	}

	.mat-form-field-infix {
		position: static;
		display: flex;
		align-items: center;
		border-top: 0;
		width: 100%;

		@media (max-width: $breakpoint5px) {
			flex-direction: column;
		}
	}

	.mat-form-field-appearance-legacy .mat-form-field-infix {
		padding: 0;
		margin: 0;
	}

	.mat-form-field-label-wrapper {
		position: static;
		display: flex;
		height: auto;
		order: -1;
		flex-basis: calculate_vw(100);
		max-width: calculate_vw(100);
		width: auto;
		flex-shrink: 0;
		padding-top: 0;
		margin-right: calculate_vw(15);

		@media (max-width: $breakpoint1px) {
			max-width: elemVwSize(100, $breakpoint1);
			flex-basis: elemVwSize(100, $breakpoint1);
		}

		@media (max-width: $breakpoint2px) {
			max-width: elemVwSize(100, $breakpoint2);
			flex-basis: elemVwSize(100, $breakpoint2);
		}

		@media (max-width: $breakpoint3px) {
			max-width: elemVwSize(100, $breakpoint3);
			flex-basis: elemVwSize(100, $breakpoint3);
		}

		@media (max-width: $breakpoint4px) {
			max-width: elemVwSize(100, $breakpoint4);
			flex-basis: elemVwSize(100, $breakpoint4);
		}

		@media (max-width: $breakpoint5px) {
			max-width: none;
			flex-shrink: 0;
			flex-basis: auto;
			width: 100%;
			margin-right: 0;
			margin-bottom: 5px;
		}
	}

	.f-basis-label {
		.mat-form-field-label-wrapper {
			flex-basis: auto;
		}
	}

	.mat-form-field-label {
		position: static;
		@include font-size(17);
		font-weight: 700;
		font-family: "Source Sans Pro", sans-serif;
		white-space: normal;

		@media (max-width: $breakpoint1px) {
			@include media-font-size(16, $breakpoint1);
		}

		@media (max-width: $breakpoint2px) {
			@include media-font-size(16, $breakpoint2);
		}

		@media (max-width: $breakpoint3px) {
			@include media-font-size(16, $breakpoint3);
		}

		@media (max-width: $breakpoint4px) {
			@include media-font-size(16, $breakpoint4);
		}

		@media (max-width: $breakpoint5px) {
			font-size: 15px;
		}
	}

	.mat-form-field.mat-focused .mat-form-field-label {
		color: $form-primary-text-color;
	}

	.mat-form-field-appearance-legacy .mat-form-field-label {
		color: $form-primary-text-color;
	}

	.mat-form-field-empty.mat-form-field-label {
		display: flex;
		align-items: flex-start;
		width: auto;
	}

	.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
		display: flex;
		align-items: center;
	}

	.mat-input-element {
		border: 1px solid #A4AFB7;
		background-color: rgba(#CECFD0, 0.26);
		padding: calculate_vw(9) calculate_vw(9);
		@include font-size(15);
		line-height: 1;
		flex-grow: 1;
		min-width: 100px;
		caret-color: $form-primary-text-color;

		@media (max-width: $breakpoint1px) {
			@include media-font-size(16, $breakpoint1);
			padding: elemVwSize(10, $breakpoint1);
		}

		@media (max-width: $breakpoint2px) {
			@include media-font-size(16, $breakpoint2);
			padding: elemVwSize(10, $breakpoint2);
		}

		@media (max-width: $breakpoint3px) {
			@include media-font-size(16, $breakpoint3);
			padding: elemVwSize(10, $breakpoint3);
		}

		@media (max-width: $breakpoint4px) {
			@include media-font-size(16, $breakpoint4);
			padding: elemVwSize(10, $breakpoint4);
		}

		@media (max-width: $breakpoint5px) {
			padding: 8px 10px;
			font-size: 16px;
		}
	}

	textarea.mat-input-element {
		//height: auto !important;
		line-height: 1.32;
		min-height: calculate_vw(100);

		@media (max-width: $breakpoint1px) {
			min-height: elemVwSize(100, $breakpoint1);
		}

		@media (max-width: $breakpoint2px) {
			min-height: elemVwSize(100, $breakpoint2);
		}

		@media (max-width: $breakpoint3px) {
			min-height: elemVwSize(100, $breakpoint3);
		}

		@media (max-width: $breakpoint4px) {
			min-height: elemVwSize(100, $breakpoint4);
		}
	}

	// Label on input focus
	.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float {
		.mat-form-field-label {
			transform: translate3d(0, 0, 0);
		}
	}

	// Material underline
	.mat-form-field-underline {
		display: none;
	}

	// Material select
	.mat-select-trigger {
		border: 1px solid #A4AFB7;
		background-color: rgba(#CECFD0, 0.26);
		padding: calculate_vw(10) calculate_vw(10);

		@media (max-width: $breakpoint1px) {
			padding: elemVwSize(10, $breakpoint1);
		}

		@media (max-width: $breakpoint2px) {
			padding: elemVwSize(10, $breakpoint2);
		}

		@media (max-width: $breakpoint3px) {
			padding: elemVwSize(10, $breakpoint3);
		}

		@media (max-width: $breakpoint4px) {
			padding: elemVwSize(10, $breakpoint4);
		}

		@media (max-width: $breakpoint5px) {
			padding: 8px 10px;
		}
	}

	.mat-select-value {
		line-height: (24 / 18);
		@include font-size(15);

		@media (max-width: $breakpoint1px) {
			@include media-font-size(15, $breakpoint1);
		}

		@media (max-width: $breakpoint2px) {
			@include media-font-size(15, $breakpoint2);
		}

		@media (max-width: $breakpoint3px) {
			@include media-font-size(15, $breakpoint3);
		}

		@media (max-width: $breakpoint4px) {
			@include media-font-size(15, $breakpoint4);
		}

		@media (max-width: $breakpoint5px) {
			font-size: 15px;
		}
	}

	// Form group container
	.form-group__container {
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		position: relative;

		dynamic-material-input {
			width: 100%;
		}
	}

	// Form buttons
	.form-buttons {
		display: flex;
		align-items: flex-start;
		flex-basis: auto;
		margin-left: calculate_vw(10);
		margin-right: calculate_vw(-10);
		width: 100%;
		margin-left: 0;
		justify-content: flex-end;
		margin-top: calculate_vw(10);

		&_mb-bigger {
			margin-top: calculate_vw(25);
		}

		@media (max-width: $breakpoint1px) {
			margin-top: elemVwSize(10, $breakpoint1);
		}

		@media (max-width: $breakpoint2px) {
			margin-top: elemVwSize(10, $breakpoint2);
		}

		@media (max-width: $breakpoint3px) {
			margin-top: elemVwSize(10, $breakpoint3);
		}

		@media (max-width: $breakpoint4px) {
			margin-top: elemVwSize(10, $breakpoint4);
		}

		@media (max-width: $breakpoint5px) {
			margin-top: 15px;
		}
	}

	.form-button__item {
		margin-left: calculate_vw(10);
	}

	dynamic-material-input {
		flex: 1;
	}

	dynamic-material-form {
		& > .material-form-group.material-form-group--radio-grow-label {
			& > div {
				display: flex;
				.form-label {
					max-width: unset;
				}
				.mat-radio-group {
					flex-wrap: nowrap;
				}
			}
		}
		& > dynamic-material-form-control:not(:last-of-type) {
			& > div {
				margin-bottom: calculate_vw(30);

				@media (max-width: $breakpoint1px) {
					margin-bottom: elemVwSize(25, $breakpoint1);
				}

				@media (max-width: $breakpoint2px) {
					margin-bottom: elemVwSize(25, $breakpoint2);
				}

				@media (max-width: $breakpoint3px) {
					margin-bottom: elemVwSize(25, $breakpoint3);
				}

				@media (max-width: $breakpoint4px) {
					margin-bottom: elemVwSize(25, $breakpoint4);
				}

				@media (max-width: $breakpoint5px) {
					margin-bottom: 20px;
				}
			}
		}
	}

	.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
		top: 100%;
		margin-top: calculate_vw(7);

		@media (max-width: $breakpoint1px) {
			margin-top: elemVwSize(7, $breakpoint1);

			.mat-hint {
				@include media-font-size(12, $breakpoint1);
			}

			.mat-error {
				@include media-font-size(12, $breakpoint1);
			}
		}

		@media (max-width: $breakpoint2px) {
			margin-top: elemVwSize(7, $breakpoint2);

			.mat-hint {
				@include media-font-size(12, $breakpoint2);
			}

			.mat-error {
				@include media-font-size(12, $breakpoint2);
			}
		}

		@media (max-width: $breakpoint3px) {
			margin-top: elemVwSize(7, $breakpoint3);

			.mat-hint {
				@include media-font-size(12, $breakpoint3);
			}

			.mat-error {
				@include media-font-size(12, $breakpoint3);
			}
		}

		@media (max-width: $breakpoint4px) {
			margin-top: elemVwSize(7, $breakpoint4);

			.mat-hint {
				@include media-font-size(12, $breakpoint4);
			}

			.mat-error {
				@include media-font-size(12, $breakpoint4);
			}
		}

		@media (max-width: $breakpoint5px) {
			margin-top: 3px;

			.mat-hint {
				font-size: 12px;
			}

			.mat-error {
				font-size: 12px;
			}
		}
	}

	.mat-form-field-appearance-legacy .mat-form-field-wrapper {
		padding-bottom: 0;
	}

	.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
		transform: none;
		display: flex;
		align-items: center;
	}

	// Personal form
	.phone-group {
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		.mat-input-element {
			width: 100%;
		}
	}

	.phone-group__item {
		flex: 1;
		width: 100%;
	}

	.phone-group__item_text {
		display: flex;
		justify-content: flex-end;

		.form-label {
			max-width: none;
			width: auto;
			margin-right: calculate_vw(15);

			@media (max-width: $breakpoint1px) {
				margin-right: elemVwSize(15, $breakpoint1);
			}

			@media (max-width: $breakpoint2px) {
				margin-right: elemVwSize(15, $breakpoint2);
			}

			@media (max-width: $breakpoint3px) {
				margin-right: elemVwSize(15, $breakpoint3);
			}

			@media (max-width: $breakpoint4px) {
				margin-right: elemVwSize(15, $breakpoint4);
			}

			@media (max-width: $breakpoint5px) {
				margin-right: 15px;
			}
		}
	}

	.phone-text__wrapper {
		display: flex;
		margin-top: calculate_vw(10);
		position: relative;

		@media (max-width: $breakpoint1px) {
			margin-top: elemVwSize(10, $breakpoint1);
		}

		@media (max-width: $breakpoint4px) {
			margin-top: elemVwSize(10, $breakpoint4);
		}

		@media (max-width: $breakpoint4px) {
			margin-top: elemVwSize(10, $breakpoint4);
		}

		@media (max-width: $breakpoint4px) {
			margin-top: elemVwSize(10, $breakpoint4);
		}

		.mat-error {
			position: absolute;
			top: 100%;
			right: 0;
			margin-top: calculate_vw(10);
			font-size: 75%;

			@media (max-width: $breakpoint1px) {
				margin-top: elemVwSize(10, $breakpoint1);
			}

			@media (max-width: $breakpoint4px) {
				margin-top: elemVwSize(10, $breakpoint4);
			}

			@media (max-width: $breakpoint4px) {
				margin-top: elemVwSize(10, $breakpoint4);
			}

			@media (max-width: $breakpoint4px) {
				margin-top: elemVwSize(10, $breakpoint4);
			}
		}
	}

	// Datepicker styles
	.mat-form-field-suffix {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: calculate_vw(25);
		display: flex;

		.maticon-trash {
			margin-right: calculate_vw(7);
		}

		@media (max-width: $breakpoint1px) {
			right: elemVwSize(10, $breakpoint1);
		}

		@media (max-width: $breakpoint2px) {
			right: elemVwSize(10, $breakpoint2);
		}

		@media (max-width: $breakpoint3px) {
			right: elemVwSize(10, $breakpoint3);
		}

		@media (max-width: $breakpoint4px) {
			right: elemVwSize(10, $breakpoint4);
		}

		@media (max-width: $breakpoint5px) {
			right: 10px;
			top: 40px;
			bottom: 0;
			align-items: center;
			min-width: 60px;
			justify-content: space-between;

			.maticon-trash {
				width: 30px;
				height: 30px;
				font-size: 25px;
			}
		}
	}

	.mat-form-field-appearance-legacy .mat-form-field-suffix .mat-datepicker-toggle-default-icon {
		width: calculate_vw(25);
		height: calculate_vw(25);

		@media (max-width: $breakpoint1px) {
			width: elemVwSize(25, $breakpoint1);
			height: elemVwSize(25, $breakpoint1);
		}

		@media (max-width: $breakpoint2px) {
			width: elemVwSize(25, $breakpoint2);
			height: elemVwSize(25, $breakpoint2);
		}

		@media (max-width: $breakpoint3px) {
			width: elemVwSize(25, $breakpoint3);
			height: elemVwSize(25, $breakpoint3);
		}

		@media (max-width: $breakpoint4px) {
			width: elemVwSize(25, $breakpoint4);
			height: elemVwSize(25, $breakpoint4);
		}

		@media (max-width: $breakpoint5px) {
			position: absolute;
			top: -21px;
			right: -7px;
			width: 30px;
			height: 30px;
		}
	}

	.mat-datepicker-toggle {

		.mat-button-ripple.mat-ripple {
			display: none;
		}

		.mat-button-focus-overlay {
			display: none;
		}

		.mat-datepicker-toggle-default-icon {
			fill: rgba($form-primary-text-color, .6);
		}

		&-active {
			.mat-datepicker-toggle-default-icon {
				fill: $form-primary-text-color;
			}
		}

		.mat-icon-button {
			width: auto !important;
			height: auto !important;
		}
	}

	.form-element_label-wider {
		.mat-form-field-label-wrapper {
			flex-basis: 50%;
			max-width: 50%;

			@media (max-width: $breakpoint5px) {
				flex-basis: 100%;
				max-width: 100%;
			}
		}
	}

	.form_mt {

		&.name_input {
			margin-top: calculate_vw(10);

			@media (max-width: $breakpoint1px) {
				margin-top: elemVwSize(10, $breakpoint1);
			}

			@media (max-width: $breakpoint2px) {
				margin-top: elemVwSize(10, $breakpoint2);
			}

			@media (max-width: $breakpoint3px) {
				margin-top: elemVwSize(10, $breakpoint3);
			}

			@media (max-width: $breakpoint4px) {
				margin-top: elemVwSize(10, $breakpoint4);
			}

			@media (max-width: $breakpoint5px) {
				margin-top: 10px;
			}
		}

	}
}

// Form actions
.form-actions {
	display: flex;
	justify-content: flex-end;
	margin-top: calculate_vw(30);

	@media (max-width: $breakpoint1px) {
		margin-top: elemVwSize(30, $breakpoint1);
	}

	@media (max-width: $breakpoint2px) {
		margin-top: elemVwSize(30, $breakpoint2);
	}

	@media (max-width: $breakpoint3px) {
		margin-top: elemVwSize(30, $breakpoint3);
	}

	@media (max-width: $breakpoint4px) {
		margin-top: elemVwSize(30, $breakpoint4);
	}

	@media (max-width: $breakpoint5px) {
		margin-top: 30px;
	}

	&--no-mt {
		margin-top: 0;
	}

	&--mb {
		margin-bottom: calculate_vw(30);
		@media (max-width: $breakpoint1px) {
			margin-bottom: elemVwSize(30, $breakpoint1);
		}

		@media (max-width: $breakpoint2px) {
			margin-bottom: elemVwSize(30, $breakpoint2);
		}

		@media (max-width: $breakpoint3px) {
			margin-bottom: elemVwSize(30, $breakpoint3);
		}

		@media (max-width: $breakpoint4px) {
			margin-bottom: elemVwSize(30, $breakpoint4);
		}

		@media (max-width: $breakpoint5px) {
			margin-bottom: 20px;
		}
	}
	&--left {
		justify-content: flex-start;
		.form-action {
			&:first-child {
				margin-left: 0;
				margin-right: calculate_vw(10);
				@media (max-width: $breakpoint5px) {
					margin-right: 10px;
				}
			}
		}
	}
}

// View password
.view-password {
	background-color: transparent;
	border: none;
	padding: 0;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	right: 1%;
	top: 13%;
	@media (max-width: $breakpoint5px) {
		top: 30px;
	}

	&:focus {
		outline: none;
	}

	&_active {
		.view-password__icon {
			fill: $form-primary-text-color;
		}
	}
}

// View password
.code-plus {
	background-color: transparent;
	border: none;
	padding: 0;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	right: 89%;
	top: 29%;

	@media (max-width: 929px) {
		right: 85%;
		top: 28%;
	}
	@media (max-width: 767px) {
		right: 3%;
		top: 34%;
	}

	&:focus {
		outline: none;
	}

	&_icon {
		width: calculate_vw(36);
		height: calculate_vw(18);
		fill: rgba($form-primary-text-color, .4);
		pointer-events: none;
		transition: fill .2s ease;

		@media (max-width: $breakpoint1px) {
			width: elemVwSize(36, $breakpoint1);
			height: elemVwSize(18, $breakpoint1);
		}

		@media (max-width: $breakpoint2px) {
			width: elemVwSize(36, $breakpoint2);
			height: elemVwSize(18, $breakpoint2);
		}

		@media (max-width: $breakpoint3px) {
			width: elemVwSize(36, $breakpoint3);
			height: elemVwSize(18, $breakpoint3);
		}

		@media (max-width: $breakpoint4px) {
			width: elemVwSize(36, $breakpoint4);
			height: elemVwSize(18, $breakpoint4);
		}

		@media (max-width: $breakpoint5px) {
			width: 36px;
			height: 18px;
		}
		transform: rotate(180deg);
		-ms-transform: rotate(180deg);
		-moz-transform: rotate(180deg);
		-webkit-transform: rotate(180deg);
		-o-transform: rotate(180deg);
	}
}

.code-minus {
	background-color: transparent;
	border: none;
	padding: 0;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	right: 89%;
	top: 35%;

	@media (max-width: 929px) {
		right: 85%;
		top: 36%;
	}
	@media (max-width: 767px) {
		right: 3%;
		top: 40%;
	}

	&:focus {

		outline: none;
	}

	&_icon {
		width: calculate_vw(36);
		height: calculate_vw(18);
		fill: rgba($form-primary-text-color, .4);
		pointer-events: none;
		transition: fill .2s ease;

		@media (max-width: $breakpoint1px) {
			width: elemVwSize(36, $breakpoint1);
			height: elemVwSize(18, $breakpoint1);
		}

		@media (max-width: $breakpoint2px) {
			width: elemVwSize(36, $breakpoint2);
			height: elemVwSize(18, $breakpoint2);
		}

		@media (max-width: $breakpoint3px) {
			width: elemVwSize(36, $breakpoint3);
			height: elemVwSize(18, $breakpoint3);
		}

		@media (max-width: $breakpoint4px) {
			width: elemVwSize(36, $breakpoint4);
			height: elemVwSize(18, $breakpoint4);
		}

		@media (max-width: $breakpoint5px) {
			width: 36px;
			height: 18px;
		}

	}
}

.view-password__icon {
	width: calculate_vw(36);
	height: calculate_vw(18);
	fill: rgba($form-primary-text-color, .4);
	pointer-events: none;
	transition: fill .2s ease;

	@media (max-width: $breakpoint1px) {
		width: elemVwSize(36, $breakpoint1);
		height: elemVwSize(18, $breakpoint1);
	}

	@media (max-width: $breakpoint2px) {
		width: elemVwSize(36, $breakpoint2);
		height: elemVwSize(18, $breakpoint2);
	}

	@media (max-width: $breakpoint3px) {
		width: elemVwSize(36, $breakpoint3);
		height: elemVwSize(18, $breakpoint3);
	}

	@media (max-width: $breakpoint4px) {
		width: elemVwSize(36, $breakpoint4);
		height: elemVwSize(18, $breakpoint4);
	}

	@media (max-width: $breakpoint5px) {
		width: 36px;
		height: 18px;
	}
}

// Datepicker calendar
.mat-datepicker-popup {
	.mat-datepicker-content .mat-calendar {
		width: calculate_vw(296);
		height: calculate_vw(354);

		@media (max-width: $breakpoint1px) {
			width: elemVwSize(296, $breakpoint1);
			height: elemVwSize(354, $breakpoint1);
		}

		@media (max-width: $breakpoint2px) {
			width: elemVwSize(296, $breakpoint2);
			height: elemVwSize(354, $breakpoint2);
		}

		@media (max-width: $breakpoint3px) {
			width: elemVwSize(296, $breakpoint3);
			height: elemVwSize(354, $breakpoint3);
		}

		@media (max-width: $breakpoint4px) {
			width: elemVwSize(296, $breakpoint4);
			height: elemVwSize(354, $breakpoint4);
		}

		@media (max-width: $breakpoint5px) {
			width: 296px;
			height: 354px;
		}
	}

	.mat-calendar-body {

		tr[aria-hidden=true] {
			display: none !important;
		}

		@include font-size(13);

		@media (max-width: $breakpoint1px) {
			@include media-font-size(13, $breakpoint1);
		}

		@media (max-width: $breakpoint2px) {
			@include media-font-size(13, $breakpoint2);
		}

		@media (max-width: $breakpoint3px) {
			@include media-font-size(13, $breakpoint3);
		}

		@media (max-width: $breakpoint4px) {
			@include media-font-size(13, $breakpoint4);
		}

		@media (max-width: $breakpoint5px) {
			font-size: 13px;
		}
	}

	.mat-calendar-body-label {
		opacity: 0 !important;
		visibility: hidden !important;
	}

	.mat-calendar-body-label, .mat-calendar-period-button {
		@include font-size(14);

		@media (max-width: $breakpoint1px) {
			@include media-font-size(14, $breakpoint1);
		}

		@media (max-width: $breakpoint2px) {
			@include media-font-size(14, $breakpoint2);
		}

		@media (max-width: $breakpoint3px) {
			@include media-font-size(14, $breakpoint3);
		}

		@media (max-width: $breakpoint4px) {
			@include media-font-size(14, $breakpoint4);
		}

		@media (max-width: $breakpoint5px) {
			font-size: 14px;
		}
	}

	.mat-calendar-arrow {
		border-left: calculate_vw(5) solid transparent;
		border-right: calculate_vw(5) solid transparent;
		border-top-width: calculate_vw(5);
		border-top-style: solid;
		margin: 0 0 0 calculate_vw(5);

		@media (max-width: $breakpoint1px) {
			border-left: elemVwSize(5, $breakpoint1);
			border-right: elemVwSize(5, $breakpoint1);
			border-top-width: elemVwSize(5, $breakpoint1);
			margin: 0 0 0 elemVwSize(5, $breakpoint1);
		}

		@media (max-width: $breakpoint2px) {
			border-left: elemVwSize(5, $breakpoint2);
			border-right: elemVwSize(5, $breakpoint2);
			border-top-width: elemVwSize(5, $breakpoint2);
			margin: 0 0 0 elemVwSize(5, $breakpoint2);
		}

		@media (max-width: $breakpoint3px) {
			border-left: elemVwSize(5, $breakpoint3);
			border-right: elemVwSize(5, $breakpoint3);
			border-top-width: elemVwSize(5, $breakpoint3);
			margin: 0 0 0 elemVwSize(5, $breakpoint3);
		}

		@media (max-width: $breakpoint4px) {
			border-left: elemVwSize(5, $breakpoint4);
			border-right: elemVwSize(5, $breakpoint4);
			border-top-width: elemVwSize(5, $breakpoint4);
			margin: 0 0 0 elemVwSize(5, $breakpoint4);
		}

		@media (max-width: $breakpoint5px) {
			border-left: 5px;
			border-right: 5px;
			border-top-width: 5px;
			margin: 0 0 0 5px;
		}
	}

	.mat-calendar-next-button::after, .mat-calendar-previous-button::after {
		margin: calculate_vw(15.5);
		border-top-width: calculate_vw(2);

		@media (max-width: $breakpoint1px) {
			margin: elemVwSize(15.5, $breakpoint1);
			border-top-width: elemVwSize(2, $breakpoint1);
		}

		@media (max-width: $breakpoint2px) {
			margin: elemVwSize(15.5, $breakpoint2);
			border-top-width: elemVwSize(2, $breakpoint2);
		}

		@media (max-width: $breakpoint3px) {
			margin: elemVwSize(15.5, $breakpoint3);
			border-top-width: elemVwSize(2, $breakpoint3);
		}

		@media (max-width: $breakpoint4px) {
			margin: elemVwSize(15.5, $breakpoint4);
			border-top-width: elemVwSize(2, $breakpoint4);
		}

		@media (max-width: $breakpoint5px) {
			margin: 15.5px;
			border-top-width: 2px;
		}
	}

	.mat-calendar-previous-button::after {
		border-left-width: calculate_vw(2);
		transform: translateX(calculate_vw(2)) rotate(-45deg);

		@media (max-width: $breakpoint1px) {
			border-left-width: elemVwSize(2, $breakpoint1);
			transform: translateX(elemVwSize(2, $breakpoint1)) rotate(-45deg);
		}

		@media (max-width: $breakpoint2px) {
			border-left-width: elemVwSize(2, $breakpoint2);
			transform: translateX(elemVwSize(2, $breakpoint2)) rotate(-45deg);
		}

		@media (max-width: $breakpoint3px) {
			border-left-width: elemVwSize(2, $breakpoint3);
			transform: translateX(elemVwSize(2, $breakpoint3)) rotate(-45deg);
		}

		@media (max-width: $breakpoint4px) {
			border-left-width: elemVwSize(2, $breakpoint4);
			transform: translateX(elemVwSize(2, $breakpoint4)) rotate(-45deg);
		}

		@media (max-width: $breakpoint5px) {
			border-left-width: 2px;
			transform: translateX(2px) rotate(-45deg);
		}
	}

	.mat-calendar-next-button::after {
		border-right-width: calculate_vw(2);
		transform: translateX(calculate_vw(-2)) rotate(45deg);

		@media (max-width: $breakpoint1px) {
			border-right-width: elemVwSize(2, $breakpoint1);
			transform: translateX(elemVwSize(-2, $breakpoint1)) rotate(45deg);
		}

		@media (max-width: $breakpoint2px) {
			border-right-width: elemVwSize(2, $breakpoint2);
			transform: translateX(elemVwSize(-2, $breakpoint2)) rotate(45deg);
		}

		@media (max-width: $breakpoint3px) {
			border-right-width: elemVwSize(2, $breakpoint3);
			transform: translateX(elemVwSize(-2, $breakpoint3)) rotate(45deg);
		}

		@media (max-width: $breakpoint4px) {
			border-right-width: elemVwSize(2, $breakpoint4);
			transform: translateX(elemVwSize(-2, $breakpoint4)) rotate(45deg);
		}

		@media (max-width: $breakpoint5px) {
			border-right-width: 2px;
			transform: translateX(-2px) rotate(45deg);
		}
	}

	.mat-icon-button {
		width: calculate_vw(40);
		height: calculate_vw(40);
		line-height: calculate_vw(40);

		@media (max-width: $breakpoint1px) {
			width: elemVwSize(40, $breakpoint1);
			height: elemVwSize(40, $breakpoint1);
			line-height: elemVwSize(40, $breakpoint1);
		}

		@media (max-width: $breakpoint2px) {
			width: elemVwSize(40, $breakpoint2);
			height: elemVwSize(40, $breakpoint2);
			line-height: elemVwSize(40, $breakpoint2);
		}

		@media (max-width: $breakpoint3px) {
			width: elemVwSize(40, $breakpoint3);
			height: elemVwSize(40, $breakpoint3);
			line-height: elemVwSize(40, $breakpoint3);
		}

		@media (max-width: $breakpoint4px) {
			width: elemVwSize(40, $breakpoint4);
			height: elemVwSize(40, $breakpoint4);
			line-height: elemVwSize(40, $breakpoint4);
		}

		@media (max-width: $breakpoint5px) {
			width: 40px;
			height: 40px;
			line-height: 40px;
		}
	}

	.mat-calendar-table-header th {
		font-size: calculate_vw(11);

		@media (max-width: $breakpoint1px) {
			@include media-font-size(11, $breakpoint1);
		}

		@media (max-width: $breakpoint2px) {
			@include media-font-size(11, $breakpoint2);
		}

		@media (max-width: $breakpoint3px) {
			@include media-font-size(11, $breakpoint3);
		}

		@media (max-width: $breakpoint4px) {
			@include media-font-size(11, $breakpoint4);
		}

		@media (max-width: $breakpoint5px) {
			font-size: 11px;
		}
	}
}

// Site input
.site-input {
	background-color: rgba(#CECFD0, .26);
	border: 1px solid #a4afb7 !important;
	padding: calculate_vw(5) 0;
	font-family: "Open Sans", sans-serif;

	@media (min-width: 1920px) {
		border: calculate_vw(1) solid #A4AFB7;
	}
}

// Checkbox like a radio
.checkbox-like-radio {
	.mat-checkbox-inner-container {
		display: none;
	}

	.mat-checkbox-layout {
		border: 1px solid #707070;
		background-color: #E9E9F0;
		color: rgba(#43425D, .4);
		@include font-size(20);
		padding: calculate_vw(5);
		text-align: center;
		-webkit-user-select: none; /* Chrome all / Safari all */
		-moz-user-select: none; /* Firefox all */
		-ms-user-select: none; /* IE 10+ */
		user-select: none; /* Likely future */

		@media (max-width: $breakpoint1px) {
			padding: elemVwSize(5, $breakpoint1);
			@include media-font-size(20, $breakpoint1);
		}

		@media (max-width: $breakpoint2px) {
			padding: elemVwSize(5, $breakpoint2);
			@include media-font-size(20, $breakpoint2);
		}

		@media (max-width: $breakpoint3px) {
			padding: elemVwSize(5, $breakpoint3);
			@include media-font-size(20, $breakpoint3);
		}

		@media (max-width: $breakpoint4px) {
			padding: elemVwSize(5, $breakpoint4);
			@include media-font-size(20, $breakpoint4);
		}

		@media (max-width: $breakpoint5px) {
			padding: 5px;
			font-size: 18px;
		}
	}

	&.mat-checkbox-checked {
		.mat-checkbox-layout {
			color: #43425D;
			background-color: #F2EE71;
		}
	}

	&:not(:last-of-type) {
		margin-bottom: calculate_vw(5);

		@media (max-width: $breakpoint1px) {
			margin-bottom: elemVwSize(5, $breakpoint1);
		}

		@media (max-width: $breakpoint2px) {
			margin-bottom: elemVwSize(5, $breakpoint2);
		}

		@media (max-width: $breakpoint3px) {
			margin-bottom: elemVwSize(5, $breakpoint3);
		}

		@media (max-width: $breakpoint4px) {
			margin-bottom: elemVwSize(5, $breakpoint4);
		}

		@media (max-width: $breakpoint5px) {
			margin-bottom: 5px;
		}
	}
}

.audit-item__checkbox {

	.mat-checkbox-layout {
		display: block;
		width: 100%;
	}
}

.form-row {
	display: flex;

	@media (max-width: $breakpoint2px) {
		flex-wrap: wrap;
	}

	&_col_flex {

		flex: 1;
		flex-direction: column;
	}

	&_mb {

		&:not(:last-of-type) {
			& > div {
				margin-bottom: calculate_vw(10);

				@media (max-width: $breakpoint1px) {
					margin-bottom: elemVwSize(10, $breakpoint1);
				}

				@media (max-width: $breakpoint2px) {
					margin-bottom: elemVwSize(10, $breakpoint2);
				}

				@media (max-width: $breakpoint3px) {
					margin-bottom: elemVwSize(10, $breakpoint3);
				}

				@media (max-width: $breakpoint4px) {
					margin-bottom: elemVwSize(10, $breakpoint4);
				}

				@media (max-width: $breakpoint5px) {
					margin-bottom: 7px;
				}
			}
		}
	}

	&_wrap {
		flex-wrap: wrap;
		margin-right: calculate_vw(-25);

		@media (max-width: $breakpoint1px) {
			margin-right: elemVwSize(-25, $breakpoint1);
		}

		@media (max-width: $breakpoint2px) {
			margin-right: elemVwSize(-25, $breakpoint2);
		}

		@media (max-width: $breakpoint3px) {
			margin-right: elemVwSize(-25, $breakpoint3);
		}

		@media (max-width: $breakpoint4px) {
			margin-right: elemVwSize(-25, $breakpoint4);
		}

		@media (max-width: $breakpoint5px) {
			margin-right: 0;
		}
	}
}

.form-element {
	flex: 1;

	&__warning {
		.mat-input-element {
			color: $warning-color !important;
		}
		.mat-select-value {
			color: $warning-color !important;
		}

	}

	@media (max-width: $breakpoint2px) {
		flex-basis: 100%;
	}

	&:not(:last-of-type) {
		margin-right: calculate_vw(25);

		@media (max-width: $breakpoint1px) {
			margin-right: elemVwSize(25, $breakpoint1);
		}

		@media (max-width: $breakpoint2px) {
			margin-right: 0;
			margin-bottom: elemVwSize(15, $breakpoint2);
		}

		@media (max-width: $breakpoint3px) {
			margin-bottom: elemVwSize(15, $breakpoint3);
		}

		@media (max-width: $breakpoint4px) {
			margin-bottom: elemVwSize(15, $breakpoint4);
		}

		@media (max-width: $breakpoint5px) {
			margin-right: 0;
			margin-bottom: 10px;
		}
	}


	&_bottom-valign {
		display: flex;
		align-items: flex-end;

		& > div {
			width: 100%;
		}
	}

	&_x2 {
		flex: 2;
	}

	&_x3 {
		flex: 3;
	}

	&_hide-label {
		textarea.mat-input-element {
			//height: auto !important;
			//min-height: 50px;
		}

		.mat-form-field-label-wrapper {
			display: none;
		}
	}

	&_1-2 {
		flex-basis: calc(50% - 1.5625vw);
		max-width: calc(50% - 1.5625vw);

		@media (max-width: $breakpoint5px) {
			flex-basis: 100%;
			max-width: 100%;
		}

		&:last-of-type {
			margin-right: calculate_vw(25);

			@media (max-width: $breakpoint1px) {
				margin-right: elemVwSize(25, $breakpoint1);
			}

			@media (max-width: $breakpoint2px) {
				margin-right: elemVwSize(25, $breakpoint2);
			}

			@media (max-width: $breakpoint3px) {
				margin-right: elemVwSize(25, $breakpoint3);
			}

			@media (max-width: $breakpoint4px) {
				margin-right: elemVwSize(25, $breakpoint4);
			}

			@media (max-width: $breakpoint5px) {
				margin-right: 0;
			}
		}
	}

	&_right-side {
		margin-left: auto;
	}

	&_br2-full {
		@media (max-width: $breakpoint2px) {
			flex-basis: 100%;
		}
	}
}

// Hearing
.horizontal-checkboxes {
	display: flex;
	flex-wrap: wrap;
}

.horizontal-checkbox {
	flex-basis: 50%;
	max-width: 50%;
	padding-left: calculate_vw(15);
	margin-bottom: calculate_vw(5);

	@media (max-width: $breakpoint1px) {
		padding-left: elemVwSize(15, $breakpoint1);
		margin-bottom: elemVwSize(5, $breakpoint1);
	}

	@media (max-width: $breakpoint2px) {
		padding-left: elemVwSize(15, $breakpoint2);
		margin-bottom: elemVwSize(5, $breakpoint2);
	}

	@media (max-width: $breakpoint3px) {
		padding-left: elemVwSize(15, $breakpoint3);
		margin-bottom: elemVwSize(5, $breakpoint3);
	}

	@media (max-width: $breakpoint4px) {
		padding-left: elemVwSize(15, $breakpoint4);
		margin-bottom: elemVwSize(5, $breakpoint4);
	}

	&_reset-pl {
		padding-left: 0;
	}
}

// Mat checkbox
.mat-checkbox {
	.mat-checkbox-inner-container {
		width: calculate_vw(19);
		height: calculate_vw(19);
		margin-left: 0;
		margin-right: calculate_vw(8);

		@media (max-width: $breakpoint1px) {
			width: elemVwSize(19, $breakpoint1);
			height: elemVwSize(19, $breakpoint1);
			margin-right: elemVwSize(8, $breakpoint1);
		}

		@media (max-width: $breakpoint2px) {
			width: elemVwSize(19, $breakpoint2);
			height: elemVwSize(19, $breakpoint2);
			margin-right: elemVwSize(8, $breakpoint2);
		}

		@media (max-width: $breakpoint3px) {
			width: elemVwSize(19, $breakpoint3);
			height: elemVwSize(19, $breakpoint3);
			margin-right: elemVwSize(8, $breakpoint3);
		}

		@media (max-width: $breakpoint4px) {
			width: elemVwSize(19, $breakpoint4);
			height: elemVwSize(19, $breakpoint4);
			margin-right: elemVwSize(8, $breakpoint4);
		}

		@media (max-width: $breakpoint5px) {
			width: 18px;
			height: 18px;
			margin-right: 8px;
		}
	}

	.mat-checkbox-layout {
		display: inline-flex;
		align-items: center;
		justify-content: center;
	}

	.mat-checkbox-frame {
		border-color: #43425D;
		border-radius: calculate_vw(6);

		@media (max-width: $breakpoint1px) {
			border-radius: elemVwSize(6, $breakpoint1);
		}

		@media (max-width: $breakpoint2px) {
			border-radius: elemVwSize(6, $breakpoint2);
		}

		@media (max-width: $breakpoint3px) {
			border-radius: elemVwSize(6, $breakpoint3);
		}

		@media (max-width: $breakpoint4px) {
			border-radius: elemVwSize(6, $breakpoint4);
		}

		@media (max-width: $breakpoint5px) {
			border-radius: 6px;
		}
	}

	.mat-checkbox-mixedmark {
		background-color: #43425D;
	}

	.mat-checkbox-checkmark-path {
		stroke: #43425D !important;
	}

	.mat-checkbox-ripple {
		display: none;
	}

	&.mat-checkbox-checked.mat-accent .mat-checkbox-background {
		background-color: transparent;
	}

	.mat-checkbox-label {
		span {
			&:last-of-type {
				@include font-size(20);
				text-transform: uppercase;
				color: #43425D;
				//font-weight: 700;

				@media (max-width: $breakpoint1px) {
					@include media-font-size(20, $breakpoint1);
				}

				@media (max-width: $breakpoint2px) {
					@include media-font-size(20, $breakpoint2);
				}

				@media (max-width: $breakpoint3px) {
					@include media-font-size(20, $breakpoint3);
				}

				@media (max-width: $breakpoint4px) {
					@include media-font-size(20, $breakpoint4);
				}

				@media (max-width: $breakpoint5px) {
					font-size: 16px;
				}
			}
		}
	}
}

// Accordeon form title

.acco-title {
	cursor: pointer;
	@include font-size(17);
	font-weight: 700;
	color: #4D4F5C;
	position: relative;
	display: flex;
	align-items: center;
	margin-bottom: calculate_vw(10);

	@media (max-width: $breakpoint1px) {
		@include media-font-size(17, $breakpoint1);
		margin-bottom: elemVwSize(10, $breakpoint1);
	}

	@media (max-width: $breakpoint2px) {
		@include media-font-size(17, $breakpoint2);
		margin-bottom: elemVwSize(10, $breakpoint2);
	}

	@media (max-width: $breakpoint3px) {
		@include media-font-size(17, $breakpoint3);
		margin-bottom: elemVwSize(10, $breakpoint3);
	}

	@media (max-width: $breakpoint4px) {
		@include media-font-size(17, $breakpoint4);
		margin-bottom: elemVwSize(10, $breakpoint4);
	}

	@media (max-width: $breakpoint5px) {
		font-size: 16px;
		margin-bottom: 10px;
	}


	&::before,
	&::after {
		content: "";
		display: block;
		background-color: #43425D;
		height: 1px;

		@media (min-width: 1921px) {
			height: calculate_vw(1);
		}
	}

	&::before {
		flex-basis: 8%;
		max-width: 8%;
	}

	&::after {
		flex: 1;
	}
}

.acco-title__text {
	flex-shrink: 0;
	margin-right: calculate_vw(8);
	margin-left: calculate_vw(8);

	@media (max-width: $breakpoint1px) {
		margin-right: elemVwSize(8, $breakpoint1);
		margin-left: elemVwSize(8, $breakpoint1);
	}

	@media (max-width: $breakpoint2px) {
		margin-right: elemVwSize(8, $breakpoint2);
		margin-left: elemVwSize(8, $breakpoint2);
	}

	@media (max-width: $breakpoint3px) {
		margin-right: elemVwSize(8, $breakpoint3);
		margin-left: elemVwSize(8, $breakpoint3);
	}

	@media (max-width: $breakpoint4px) {
		margin-right: elemVwSize(8, $breakpoint4);
		margin-left: elemVwSize(8, $breakpoint4);
	}

	@media (max-width: $breakpoint5px) {
		margin-right: 8px;
		margin-left: 8px;
	}
}

.acco-title__indicator {
	position: absolute;
	width: calculate_vw(18);
	height: calculate_vw(18);
	border-radius: 50%;
	overflow: hidden;
	background-color: #43425D;
	right: 0;
	top: 50%;
	transform: translateY(-50%) rotate(135deg);
	display: flex;
	align-items: center;
	justify-content: center;
	transition: transform .2s ease;

	@media (max-width: $breakpoint1px) {
		width: elemVwSize(18, $breakpoint1);
		height: elemVwSize(18, $breakpoint1);
	}

	@media (max-width: $breakpoint2px) {
		width: elemVwSize(18, $breakpoint2);
		height: elemVwSize(18, $breakpoint2);
	}

	@media (max-width: $breakpoint3px) {
		width: elemVwSize(18, $breakpoint3);
		height: elemVwSize(18, $breakpoint3);
	}

	@media (max-width: $breakpoint4px) {
		width: elemVwSize(18, $breakpoint4);
		height: elemVwSize(18, $breakpoint4);
	}

	@media (max-width: $breakpoint5px) {
		width: 18px;
		height: 18px;
	}

	&_active {
		transform: translateY(-50%) rotate(0);
	}
}

.acco-title__icon {
	width: calculate_vw(8);
	height: calculate_vw(8);
	fill: #fff;

	@media (max-width: $breakpoint1px) {
		width: elemVwSize(8, $breakpoint1);
		height: elemVwSize(8, $breakpoint1);
	}

	@media (max-width: $breakpoint2px) {
		width: elemVwSize(8, $breakpoint2);
		height: elemVwSize(8, $breakpoint2);
	}

	@media (max-width: $breakpoint3px) {
		width: elemVwSize(8, $breakpoint3);
		height: elemVwSize(8, $breakpoint3);
	}

	@media (max-width: $breakpoint4px) {
		width: elemVwSize(8, $breakpoint4);
		height: elemVwSize(8, $breakpoint4);
	}

	@media (max-width: $breakpoint5px) {
		width: 8px;
		height: 8px;
	}
}

// Form margin bottom
.form_mb {
	margin-bottom: calculate_vw(10);

	@media (max-width: $breakpoint1px) {
		margin-bottom: elemVwSize(10, $breakpoint1);
	}

	@media (max-width: $breakpoint2px) {
		margin-bottom: elemVwSize(10, $breakpoint2);
	}

	@media (max-width: $breakpoint3px) {
		margin-bottom: elemVwSize(10, $breakpoint3);
	}

	@media (max-width: $breakpoint4px) {
		margin-bottom: elemVwSize(10, $breakpoint4);
	}

	@media (max-width: $breakpoint5px) {
		margin-bottom: 10px;
	}
}

.form_mt {
	margin-top: calculate_vw(10);

	@media (max-width: $breakpoint1px) {
		margin-top: elemVwSize(10, $breakpoint1);
	}

	@media (max-width: $breakpoint2px) {
		margin-top: elemVwSize(10, $breakpoint2);
	}

	@media (max-width: $breakpoint3px) {
		margin-top: elemVwSize(10, $breakpoint3);
	}

	@media (max-width: $breakpoint4px) {
		margin-top: elemVwSize(10, $breakpoint4);
	}

	@media (max-width: $breakpoint5px) {
		margin-top: 10px;
	}
}

.row-mb {
	margin-bottom: calculate_vw(30);

	@media (max-width: $breakpoint1px) {
		margin-bottom: elemVwSize(30, $breakpoint1);
	}

	@media (max-width: $breakpoint2px) {
		margin-bottom: elemVwSize(30, $breakpoint2);
	}

	@media (max-width: $breakpoint3px) {
		margin-bottom: elemVwSize(30, $breakpoint3);
	}

	@media (max-width: $breakpoint4px) {
		margin-bottom: elemVwSize(30, $breakpoint4);
	}

	&__warning {

		.mat-form-field-label {
			color: $warning-color !important;
		}

	}
}
.row-mb-half {
	margin-bottom: calculate_vw(5);

	@media (max-width: $breakpoint1px) {
		margin-bottom: elemVwSize(5, $breakpoint1);
	}

	@media (max-width: $breakpoint2px) {
		margin-bottom: elemVwSize(5, $breakpoint2);
	}

	@media (max-width: $breakpoint3px) {
		margin-bottom: elemVwSize(5, $breakpoint3);
	}

	@media (max-width: $breakpoint4px) {
		margin-bottom: elemVwSize(5, $breakpoint4);
	}
}

.half-width {
	width: 50%;

	@media (max-width: $breakpoint1px) {
		width: 100%;
	}
}

// Mobile forms
.mobile-forms {

	.mat-accordion {
		box-shadow: none;
		border-radius: 0;
	}

	.mat-expansion-panel {
		border-radius: 0;

		&:not(:last-of-type) {
			margin-bottom: 10px;
		}

		// Mobile acco indicator rotate
		&.mat-expanded {

			.acc-indicator {
				transform: translateY(-50%) rotate(135deg);
			}
		}

		// Form frame
		.form-fieldset {
			border: none;
			padding: 0;
		}

		.form-legend {
			display: none;
		}

		.form-content {
			padding: 0;
		}

		.close-modal {
			display: none;
		}
	}

	.mat-expansion-panel-spacing {
		margin-top: 10px;
	}

	.mat-expansion-panel-header {
		background-color: #43425D;
		border-radius: 0;
		max-height: 42px !important;
		font-size: 18px;
		font-family: "Source Sans Pro", sans-serif;
		position: relative;

		&:focus {
			background-color: #43425D;
		}
	}

	.mat-expansion-panel:not(.mat-expanded):not([aria-disabled='true']) .mat-expansion-panel-header:hover {
		background-color: #43425D;
	}

	.mat-expansion-panel-header.mat-expanded:focus,
	.mat-expansion-panel-header.mat-expanded:hover {
		background-color: #43425D;
	}

	.mat-expansion-panel-body {
		padding: 20px;
	}

	.mat-expansion-panel-header-title {
		color: #fff;
	}

	.mat-accordion .mat-expansion-panel:first-of-type {
		border-radius: 0;
	}

	.mat-accordion .mat-expansion-panel:last-of-type {
		border-radius: 0;
	}

	.mat-expansion-panel:not([class*='mat-elevation-z']) {
		box-shadow: 0 0 10px rgba(#000, .15);
	}
}

// Acc indicator
.acc-indicator {
	position: absolute;
	display: block;
	height: 22px;
	width: 22px;
	background-color: #F2EE71;
	border: 2px solid #fff;
	border-radius: 50%;
	right: 15px;
	top: 50%;
	transform: translateY(-50%);
	transition: transform .2s ease;

	&::before {
		content: "";
		height: 1px;
		width: 10px;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		background-color: #000;
	}

	&::after {
		content: "";
		width: 1px;
		height: 10px;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		background-color: #000;
	}
}

quill-editor {
	//
	//height: 100%;
	//display: flex;
	//flex-direction: column;
	//

	[quill-editor-element] {
		line-height: 1.32;
		min-height: calculate_vw(300);
		max-height: calculate_vw(300);
		@media (max-width: $breakpoint1px) {
			min-height: elemVwSize(300, $breakpoint1);
			max-height: elemVwSize(300, $breakpoint1);
		}

		@media (max-width: $breakpoint2px) {
			min-height: elemVwSize(300, $breakpoint2);
			max-height: elemVwSize(300, $breakpoint2);
		}

		@media (max-width: $breakpoint3px) {
			min-height: elemVwSize(300, $breakpoint3);
			max-height: elemVwSize(300, $breakpoint3);
		}

		@media (max-width: $breakpoint4px) {
			min-height: elemVwSize(300, $breakpoint4);
			max-height: elemVwSize(300, $breakpoint4);
		}
	}

	/* Set dropdown font-families */

	[quill-editor-toolbar] .ql-font span[data-label="Aref Ruqaa"]::before {
		font-family: "Aref Ruqaa";
	}

	[quill-editor-toolbar] .ql-font span[data-label="Mirza"]::before {
		font-family: "Mirza";
	}

	[quill-editor-toolbar] .ql-font span[data-label="Roboto"]::before {
		font-family: "Roboto";
	}

	/* Set content font-families */

	.ql-font-mirza {
		font-family: "Mirza";
	}

	.ql-font-aref {
		font-family: "Aref Ruqaa";
	}

	pre {
		background-color: #fcfcfc;
		padding: 5px;
		border: 1px solid #b2b2b2;
		color: #363030;
	}


}

app-ltd-info > quill-editor {
	[quill-editor-element] {
		line-height: 1.32;
		flex: 1;
		display: flex;
		flex-direction: column;
	}
}


.row_select{
	.mat-form-field-label-wrapper{
		margin-bottom: 0.5em !important;
	}
	select{
		padding: 10px 10px !important;
	}

	.mat-form-field-type-mat-native-select .mat-form-field-infix::after{
		margin-right: 1em;
		margin-top: -0.6em;
	}
}

.row_checkbox{
	margin-top: 2em;

	.form-label{
		padding-top: 0.4em;
	}
}

.meters-time{
	max-height: 3.5em;
	@media screen and (max-width: $breakpoint5px) {
		margin-top: 2.2747952684vw;
	}
}


.cursor_pointer{
	cursor: pointer;
}


::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
	color: red;
	opacity: 1; /* Firefox */
}


