@import "../../../../@theme/style/variables/calculate_vw";
@import "../../../../@theme/style/variables/colors";
@import "../../../../@theme/style/variables/breakpoints";

.form-action {
  display: flex;
  align-items: center;
  border: 0;
  cursor: pointer;
  color: #fff;
  padding: calculate_vw(8) calculate_vw(12);
  @include font-size(15);
  background-color: $primary-color;
  transition: background-color .2s ease;
  margin-left: calculate_vw(10);

  .mat-icon {

    @media (min-width: 1921px) {
      height: calculate_vw(24);
      width: calculate_vw(24);
    }
  }

  &_add {
    white-space: nowrap;
  }

  &:hover {
    background-color: darken($primary-color, 10%);
  }

  &:active {
    background-color: darken($primary-color, 4%);
  }

  &:focus {
    outline: none;
  }

  &_accent {
    background-color: $accent-color;

    &:hover {
      background-color: darken($accent-color, 10%);
    }

    &:active {
      background-color: darken($accent-color, 4%);
    }
  }

  &_warn {
    background-color: $warning-color;

    &:hover {
      background-color: darken($warning-color, 10%);
    }

    &:active {
      background-color: darken($warning-color, 4%);
    }
  }

  &_disabled {
    background-color: gray;
	cursor: default;

    &:hover {
      background-color: darken(gray, 10%);
    }

    &:active {
      background-color: darken(gray, 4%);
    }
  }
	&_green {
		background: $accent-color;
		margin-left: 0;
		&:hover {
			background-color: darken($accent-color, 10%);
		}

		&:active {
			background-color: darken($accent-color, 4%);
		}
	}

  &_dark {
    background-color: $form-primary-text-color;

    &:hover {
      background-color: darken($form-primary-text-color, 10%);
    }

    &:active {
      background-color: darken($form-primary-text-color, 4%);
    }
  }

  &_vertical {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $primary-color;
    padding: calculate_vw(4) calculate_vw(4);
    background-color: transparent;
    transition: background-color .2s ease, color .2s ease;
    font-weight: 600;

    .save-action__icon {
      margin-right: 0;
      margin-bottom: calculate_vw(5);
      fill: $primary-color;
      width: calculate_vw(35);
      height: calculate_vw(35);
    }

    &:hover {
      background-color: transparent;
      color: $accent-color;

      .save-action__icon {
        fill: $accent-color;
      }
    }
  }

  @media (max-width: $breakpoint1px) {
    padding: elemVwSize(8, $breakpoint1) elemVwSize(12, $breakpoint1);
    @include media-font-size(15, $breakpoint1);
  }

  @media (max-width: $breakpoint2px) {
    padding: elemVwSize(8, $breakpoint2) elemVwSize(12, $breakpoint2);
    @include media-font-size(15, $breakpoint2);
  }

  @media (max-width: $breakpoint3px) {
    padding: elemVwSize(8, $breakpoint3) elemVwSize(12, $breakpoint3);
    @include media-font-size(15, $breakpoint3);
  }

  @media (max-width: $breakpoint4px) {
    padding: elemVwSize(8, $breakpoint4) elemVwSize(12, $breakpoint4);
    @include media-font-size(15, $breakpoint4);
  }

  @media (max-width: $breakpoint5px) {
    padding: 6px 10px;
    font-size: 14px;
    margin-left: 10px;
  }
}

.save-action__icon {
  width: calculate_vw(20);
  height: calculate_vw(20);
  margin-right: calculate_vw(12);
  fill: #fff;
  transition: fill .2s ease;

  @media (max-width: $breakpoint1px) {
    width: elemVwSize(20, $breakpoint1);
    height: elemVwSize(20, $breakpoint1);
  }

  @media (max-width: $breakpoint2px) {
    width: elemVwSize(20, $breakpoint2);
    height: elemVwSize(20, $breakpoint2);
  }

  @media (max-width: $breakpoint3px) {
    width: elemVwSize(20, $breakpoint3);
    height: elemVwSize(20, $breakpoint3);
  }

  @media (max-width: $breakpoint4px) {
    width: elemVwSize(20, $breakpoint4);
    height: elemVwSize(20, $breakpoint4);
  }

  @media (max-width: $breakpoint5px) {
    width: 18px;
    height: 18px;
    margin-right: 8px;
  }
}

